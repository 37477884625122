import { useState } from 'react';

import { mediaQueries } from 'styles/mediaQueries';

import { useResize } from './useResize';

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useResize({
    fixedElement: document.body,
    onResize: () => {
      setIsMobile(matchMedia(mediaQueries.mobile).matches);
    }
  });

  return isMobile;
};
