import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Root } from './ExpandableCard.styles';

interface Props {
  basePath: string;
  children: (props: {
    collapse: () => void;
    expand: () => void;
    isExpanded: boolean;
  }) => React.ReactNode;
  path: string;
}

export const ExpandableCard: React.FC<Props> = ({ basePath, children, path }) => {
  const history = useHistory();
  const location = useLocation();

  const rootRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const expand = useCallback(() => {
    setTimeout(() => history.push(path));
  }, [history, path]);

  const collapse = useCallback(() => {
    setIsExpanded(false);

    history.push(basePath);
  }, [history, basePath]);

  const scrollIntoView = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (rootRef.current === null) return;

    document.documentElement.scrollBy({
      behavior: 'smooth',
      top: rootRef.current.getBoundingClientRect().top - 24
    });
  }, []);

  const handlePathChange = useCallback(
    (newPath: string) => {
      if (newPath !== path) return;
      setIsExpanded(true);
      void scrollIntoView();
    },
    [path, scrollIntoView]
  );

  useEffect(() => {
    const unregister = history.listen(({ pathname }) => handlePathChange(pathname));
    return unregister;
  }, [history, handlePathChange]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handlePathChange(location.pathname), []);

  const handleDocumentClick = useCallback(
    (event: MouseEvent) => {
      if (
        !isExpanded ||
        !rootRef.current ||
        event.target === rootRef.current ||
        rootRef.current.contains(event.target as Node) ||
        (document.getElementById('root') !== event.target &&
          !document.getElementById('root')!.contains(event.target as Node))
      ) {
        return;
      }
      collapse();
    },
    [isExpanded, collapse]
  );

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => document.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  return (
    <Root
      $isExpanded={isExpanded}
      onClick={() => {
        if (isExpanded) return;
        expand();
      }}
      ref={rootRef}
    >
      {children({ collapse, expand, isExpanded })}
    </Root>
  );
};
