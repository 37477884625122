import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useResize } from 'hooks/useResize';
import { Lightbox } from 'components/Lightbox/Lightbox';

import {
  Root,
  ScrollButtonLeft,
  ScrollButtonRight,
  ScrollContainer,
  Image,
  ScrollButtonIcon
} from './ImageCarousel.styles';

const scrollStep = 320;

interface Props {
  imageUrls: readonly string[];
  lightboxDisabled?: boolean;
}

export const ImageCarousel: React.FC<Props> = ({ imageUrls, lightboxDisabled }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const rootWidth = useRef(0);

  const [isMinScrollLevel, setIsMinScrollLevel] = useState(false);
  const [isMaxScrollLevel, setIsMaxScrollLevel] = useState(false);

  const handleScrollOrResize = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer === null) return;

    setIsMinScrollLevel(scrollContainer.scrollLeft === 0);
    setIsMaxScrollLevel(
      scrollContainer.scrollLeft + Math.ceil(rootWidth.current) >= scrollContainer.scrollWidth
    );
  }, []);

  const { ref: rootRef } = useResize({
    onResize: (rect) => {
      rootWidth.current = rect.width;
      handleScrollOrResize();
    }
  });

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer === null) return;

    scrollContainer.addEventListener('scroll', handleScrollOrResize);

    return () => {
      if (scrollContainer === null) return;
      scrollContainer.removeEventListener('scroll', handleScrollOrResize);
    };
  }, [handleScrollOrResize]);

  const changeScrollLevel = useCallback((change: number) => {
    scrollContainerRef.current?.scrollBy({
      behavior: 'smooth',
      left: change
    });
  }, []);

  const [lightboxIndex, setLightboxIndex] = useState<number>();

  return (
    <>
      <Lightbox imageUrls={imageUrls} index={lightboxIndex} setIndex={setLightboxIndex} />
      <Root ref={rootRef}>
        {!isMinScrollLevel && (
          <ScrollButtonLeft
            onClick={(event) => {
              event.stopPropagation();
              changeScrollLevel(-scrollStep);
            }}
          >
            <ScrollButtonIcon name="chevronLeft" />
          </ScrollButtonLeft>
        )}
        <ScrollContainer ref={scrollContainerRef}>
          {imageUrls.map((imageUrl, index) => (
            <Image
              key={imageUrl}
              onClick={() => {
                if (lightboxDisabled === true) return;
                setLightboxIndex(index);
              }}
              src={imageUrl}
              onLoad={() => handleScrollOrResize()}
            />
          ))}
        </ScrollContainer>
        {!isMaxScrollLevel && (
          <ScrollButtonRight
            onClick={(event) => {
              event.stopPropagation();
              changeScrollLevel(scrollStep);
            }}
          >
            <ScrollButtonIcon name="chevronRight" />
          </ScrollButtonRight>
        )}
      </Root>
    </>
  );
};
