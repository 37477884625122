import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/Button/Button';
import { Title } from 'components/Title/Title';

import {
  Quantities,
  QuantitiesCard,
  QuantitiesCurrent,
  QuantitiesArrow,
  QuantitiesInput,
  QuantitiesName,
  QuantitiesSaveButton,
  Root
} from './Administration.styles';
import { adminProductsQuery, updateProductAvailableAmountMutation } from './api';
import { AdminProductsQuery } from './__graphql__/AdminProductsQuery';
import {
  UpdateProductAvailableAmountMutation,
  UpdateProductAvailableAmountMutationVariables
} from './__graphql__/UpdateProductAvailableAmountMutation';
import { LocalClasses } from './LocalClassSignups';

export const Administration: React.FC = () => {
  const history = useHistory();

  const [adminPassword, setAdminPassword] = useState<string>();

  useEffect(() => {
    const storedPassword = localStorage.getItem('adminPassword');

    if (storedPassword === null) {
      history.push('/admin/login');
      return;
    }

    setAdminPassword(storedPassword);
  }, [history]);

  const signOut = useCallback(() => {
    localStorage.removeItem('adminPassword');
    history.push('/admin/login');
  }, [history]);

  const productsResult = useQuery<AdminProductsQuery>(adminProductsQuery);
  const products = productsResult.data?.products;

  const [updateProductAvailableAmount] = useMutation<
    UpdateProductAvailableAmountMutation,
    UpdateProductAvailableAmountMutationVariables
  >(updateProductAvailableAmountMutation, { refetchQueries: ['AdminProductsQuery'] });

  const [newQuantities, setNewQuantities] = useState<Record<string, number | null>>();

  useEffect(() => {
    if (products === undefined) return;

    const initialQuantities = products.reduce((object, product) => {
      object[product.id] = product.availableAmount;
      return object;
    }, {} as Record<string, number>);

    setNewQuantities(initialQuantities);
  }, [products]);

  const onSave = useCallback(
    async (productId: string) => {
      const newAvailableAmount = newQuantities?.[productId];

      if (newAvailableAmount === null || newAvailableAmount === undefined) {
        throw new Error(
          'Unable to update available amount because `newAvailableAmount` is `null` or `undefined`.'
        );
      }

      if (adminPassword === undefined) {
        throw new Error(
          'Unable to update available amount because `adminPassword` is `undefined`.'
        );
      }

      await updateProductAvailableAmount({
        variables: {
          adminPassword,
          newAvailableAmount,
          productId
        }
      });
    },
    [adminPassword, newQuantities, updateProductAvailableAmount]
  );

  return (
    <Root>
      <Button icon="arrowRight" onClick={() => signOut()} text="Sign out" />
      {products !== undefined && (
        <QuantitiesCard>
          <Title>Update product quantities</Title>
          <Quantities>
            {products.map((product) => {
              const newQuantity = newQuantities?.[product.id];

              return (
                <React.Fragment key={product.id}>
                  <QuantitiesName>{product.name}</QuantitiesName>
                  <QuantitiesCurrent>{product.availableAmount}</QuantitiesCurrent>
                  <QuantitiesArrow />
                  <QuantitiesInput
                    min="0"
                    onChange={(event) =>
                      setNewQuantities({
                        ...newQuantities,
                        [product.id]: isNaN(event.target.valueAsNumber)
                          ? null
                          : event.target.valueAsNumber
                      })
                    }
                    required
                    type="number"
                    value={newQuantity ?? ''}
                  />
                  <QuantitiesSaveButton
                    $isVisible={(newQuantity ?? -1) !== product.availableAmount}
                    icon="checkCircle"
                    onClick={() => void onSave(product.id)}
                    text="Save"
                    type="primary"
                  />
                </React.Fragment>
              );
            })}
          </Quantities>
        </QuantitiesCard>
      )}
      <LocalClasses />
    </Root>
  );
};
