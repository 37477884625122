import styled from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  max-width: 500px;
`;

export const CheckIcon = styled(Icon).attrs({ color: colors.success, name: 'checkCircle' })`
  height: 64px;
  width: 64px;
`;

export const Title = styled.div`
  ${mixins.text({ isHeading: true, size: 'large' })}

  text-align: center;
`;

export const Text = styled.div`
  ${mixins.text()}

  text-align: center;
`;
