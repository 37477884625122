import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { logErrorMutation } from './api';
import { LogErrorMutation, LogErrorMutationVariables } from './__graphql__/LogErrorMutation';

interface ErrorLog {
  logError: (message: string, data?: JSONObject) => void;
}

export const useErrorLog = (): ErrorLog => {
  const [logErrorRequest] = useMutation<LogErrorMutation, LogErrorMutationVariables>(
    logErrorMutation
  );

  const logError = useCallback<ErrorLog['logError']>(
    (message, data) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(message, data);
      }

      if (process.env.NODE_ENV === 'production') {
        void logErrorRequest({
          variables: {
            data,
            message
          }
        });
      }
    },
    [logErrorRequest]
  );

  return { logError };
};
