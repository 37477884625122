import gql from 'graphql-tag';

export const updateProductAvailableAmountMutation = gql`
  mutation UpdateProductAvailableAmountMutation(
    $adminPassword: String!
    $newAvailableAmount: Int!
    $productId: ID!
  ) {
    updateProductAvailableAmount(
      adminPassword: $adminPassword
      newAvailableAmount: $newAvailableAmount
      productId: $productId
    )
  }
`;

export const adminProductsQuery = gql`
  query AdminProductsQuery {
    products {
      availableAmount
      id
      name
      readableId
    }
  }
`;

export const adminLocalClassesQuery = gql`
  query AdminLocalClassesQuery($includeEventsAfter: DateTime, $includeEventsBefore: DateTime) {
    localClasses(orderBy: [{ position: asc }]) {
      descriptionLong
      id
      imageUrls
      location
      name
      readableId
      events(
        where: {
          sessions: { every: { from: { gt: $includeEventsAfter, lt: $includeEventsBefore } } }
        }
      ) {
        id
        maxStudentCount
        reservedSpotsCount
        orders(orderBy: [{ createdAt: asc }]) {
          id
          canceledAt
          email
          firstName
          lastName
          personCount
        }
        sessions(orderBy: [{ from: asc }]) {
          id
          from
          to
        }
      }
    }
  }
`;
