import React from 'react';

import { Title } from 'components/Title/Title';
import { Paragraph } from 'components/Paragraph/Paragraph';

export const ShippingPolicy: React.FC = () => {
  return (
    <div>
      <Title>Shipping Policy</Title>
      <Paragraph>
        Please allow 5-10 business days for your items to arrive. The tracking number is provided
        via PayPal for some but not all products. Please contact us if you can't see your tracking
        number on PayPal.
      </Paragraph>
    </div>
  );
};
