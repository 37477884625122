import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';

import { RadioButtonGroup } from 'components/RadioButtonGroup/RadioButtonGroup';
import { Title } from 'components/Title/Title';
import { Subtitle } from 'components/Subtitle/Subtitle';
import { Caption } from 'components/Caption/Caption';
import { getFormattedClassEventDates } from 'utils';
import { LocalClassAvailability } from 'components/LocalClassAvailability/LocalClassAvailability';
import { Message } from 'components/Message/Message';

import { adminLocalClassesQuery } from './api';
import {
  AdminLocalClassesQuery,
  AdminLocalClassesQueryVariables
} from './__graphql__/AdminLocalClassesQuery';
import {
  Events,
  Classes,
  DatesAndAvailability,
  Divider,
  Email,
  Name,
  NameAndEmail,
  Order,
  Orders,
  Root
} from './LocalClassSignups.styles';

export const LocalClasses: React.FC = () => {
  const [now] = useState(new Date().toISOString());
  const [showPastEvents, setShowPastEvents] = useState(false);

  const queryResult = useQuery<AdminLocalClassesQuery, AdminLocalClassesQueryVariables>(
    adminLocalClassesQuery,
    {
      variables: {
        includeEventsAfter: showPastEvents ? undefined : now,
        includeEventsBefore: showPastEvents ? now : undefined
      }
    }
  );

  return (
    <Root>
      <Title>Class signups</Title>
      {queryResult.data?.localClasses.length !== 0 && (
        <RadioButtonGroup<'past' | 'future'>
          onChange={(newValue) => setShowPastEvents(newValue === 'past')}
          options={[
            { label: 'Show future events', value: 'future' },
            { label: 'Show past events', value: 'past' }
          ]}
          value={showPastEvents ? 'past' : 'future'}
        />
      )}
      <Classes>
        {queryResult.data?.localClasses.length === 0 && (
          <Message>There are no classes yet.</Message>
        )}
        {queryResult.data?.localClasses.map((localClass) => {
          const sortedEvents = sortBy(localClass.events, (event) => {
            const firstSession = sortBy(event.sessions, (session) => new Date(session.from))[0];
            return new Date(firstSession.from);
          });

          return (
            <div key={localClass.id}>
              <Subtitle>{localClass.name}</Subtitle>
              {localClass.events.length === 0 && (
                <Message>
                  There are no {showPastEvents ? 'past' : 'scheduled'} events for this class yet.
                </Message>
              )}
              <Events>
                {sortedEvents.map((event) => (
                  <div key={event.id}>
                    <DatesAndAvailability>
                      <Caption>{getFormattedClassEventDates(event)}</Caption>
                      <Divider />
                      <LocalClassAvailability event={event} />
                    </DatesAndAvailability>
                    <Orders>
                      {event.orders.length === 0 && (
                        <Message>There are no signups for this event yet.</Message>
                      )}
                      {event.orders.map((order) => (
                        <Order key={order.id}>
                          <NameAndEmail>
                            <Name>
                              {order.firstName} {order.lastName}
                              {order.personCount > 1 ? ` (+${order.personCount - 1})` : ''}
                            </Name>
                            <Email>{order.email}</Email>
                          </NameAndEmail>
                        </Order>
                      ))}
                    </Orders>
                  </div>
                ))}
              </Events>
            </div>
          );
        })}
      </Classes>
    </Root>
  );
};
