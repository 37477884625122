import styled from 'styled-components';

import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

interface AvailabilityCountProps {
  $areNoSpotsLeft: boolean;
}

export const AvailabilityCount = styled.span<AvailabilityCountProps>`
  ${mixins.text({ size: 'small' })}

  color: ${({ $areNoSpotsLeft }) => ($areNoSpotsLeft ? colors.textHelper : colors.success)};
  line-height: 1;
`;

export const AvailabilityText = styled.span`
  ${mixins.text({ size: 'small' })}

  color: ${colors.textHelper};
  line-height: 1;
`;
