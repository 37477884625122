import React, { useCallback } from 'react';

import { OldPrice } from './Price.styles';

interface Props {
  className?: string;
  discount?: number;
  hideZeroFractionDigits?: boolean;
  value: number;
}

export const Price: React.FC<Props> = ({ className, discount, hideZeroFractionDigits, value }) => {
  const formatValue = useCallback(
    (input: number) =>
      new Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: hideZeroFractionDigits === true && input % 1 === 0 ? 0 : 2,
        style: 'currency'
      }).format(input),
    [hideZeroFractionDigits]
  );

  if (discount === undefined || discount === 0) {
    return <div className={className}>{formatValue(value)}</div>;
  }

  return (
    <div className={className}>
      <OldPrice>{formatValue(value)}</OldPrice>
      {formatValue(value * (1 - discount))}
    </div>
  );
};
