import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { ANALYTICS_EVENT } from '__graphql__/globalTypes';

import { localClassesQuery, trackAnalyticsEventMutation } from './api';
import { LocalClassesQuery, LocalClassesQueryVariables } from './__graphql__/LocalClassesQuery';
import { LocalClassCard } from './LocalClassCard';
import { Root } from './LocalClasses.styles';
import {
  TrackAnalyticsEventMutation,
  TrackAnalyticsEventMutationVariables
} from './__graphql__/TrackAnalyticsEventMutation';

export const LocalClasses: React.FC = () => {
  const [includeEventsAfter] = useState(new Date().toISOString());

  const location = useLocation();
  const history = useHistory();

  const [trackEvent] = useMutation<
    TrackAnalyticsEventMutation,
    TrackAnalyticsEventMutationVariables
  >(trackAnalyticsEventMutation);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const eventName = queryParams.get('track');

    if (eventName === null || !Object.keys(ANALYTICS_EVENT).includes(eventName)) return;

    void trackEvent({
      variables: {
        event: eventName as ANALYTICS_EVENT
      }
    });

    history.replace(location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryResult = useQuery<LocalClassesQuery, LocalClassesQueryVariables>(localClassesQuery, {
    variables: { includeEventsAfter }
  });

  return (
    <Root>
      {queryResult.data?.localClasses.map((localClass) => (
        <LocalClassCard
          key={localClass.readableId}
          localClass={localClass}
          refetchLocalClasses={async () => {
            await queryResult.refetch();
          }}
        />
      ))}
    </Root>
  );
};
