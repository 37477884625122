import React from 'react';
import { Link } from 'react-router-dom';

import { Navigation } from 'components/Navigation/Navigation';

import {
  Root,
  Logo,
  TrustpilotWidget,
  PromoSection,
  TheRangerPromo,
  TheRangerLogo
} from './Header.styles';

export const Header: React.FC = () => {
  return (
    <Root>
      <PromoSection>
        <TheRangerPromo>
          As seen in <TheRangerLogo alt="The Ranger" src="/assets/theRangerLogo.png" />
        </TheRangerPromo>
      </PromoSection>
      <Link to="/">
        <Logo />
      </Link>
      <TrustpilotWidget
        className="trustpilot-widget"
        data-businessunit-id="58d9541b0000ff00059f5182"
        data-locale="en-US"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-theme="light"
      >
        <a href="https://www.trustpilot.com/review/mathewsonmetals.com" target="_blank">
          Trustpilot
        </a>
      </TrustpilotWidget>
      {process.env.REACT_APP_CLASSES_ENABLED === 'true' && <Navigation />}
    </Root>
  );
};
