import styled from 'styled-components';

import { mediaQueries } from 'styles/mediaQueries';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100%;
  padding: 0 16px 96px;
`;

export const Main = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: calc(0.66 * (100% - 16px)) calc(0.34 * (100% - 16px));

  @media ${mediaQueries.mobile} {
    grid-template-columns: 100%;
  }
`;
