import { css, FlattenSimpleInterpolation } from 'styled-components';

import { StringKeys } from 'types/utils';

import { colors } from './colors';

const card = (): FlattenSimpleInterpolation => css`
  ${mixins.shadow({ size: 'large', strength: 'strong' })}

  background-color: ${colors.white};
  border-radius: 16px;
  padding: 16px;
`;

const ellipsis = (): FlattenSimpleInterpolation => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const shadowSizes = {
  default: '0 2px 8px 0',
  large: '0 4px 16px 0'
} as const;

const shadowStrengths = {
  default: 'rgba(0 0 0 / 20%)',
  strong: 'rgba(0 0 0 / 30%)'
} as const;

interface ShadowOptions {
  size?: 'default' | 'large';
  strength?: 'default' | 'strong';
}

const shadow = (options?: ShadowOptions): FlattenSimpleInterpolation => {
  const size = shadowSizes[options?.size !== undefined ? options.size : 'default'];
  const strength = shadowStrengths[options?.strength !== undefined ? options.strength : 'default'];

  return css`
    box-shadow: ${size} ${strength};
  `;
};

const fontSizes = {
  extraSmall: 12,
  small: 14,
  default: 16,
  large: 20,
  extraLarge: 24
};

export type TextSize = StringKeys<typeof fontSizes>;

const text = (options?: { isHeading?: boolean; size?: TextSize }): FlattenSimpleInterpolation => {
  const isHeading = Boolean(options?.isHeading);
  const fontFamily = isHeading ? '"Lexend Deca"' : '"Roboto"';
  const fontSize = options?.size !== undefined ? fontSizes[options.size] : fontSizes.default;

  return css`
    color: ${isHeading ? colors.text : colors.textHelper};
    font-family: ${fontFamily};
    font-size: ${fontSize}px;
    font-weight: ${isHeading ? '500' : '400'};
    line-height: ${isHeading ? 100 : 140}%;
  `;
};

export const mixins = {
  card,
  ellipsis,
  shadow,
  text
};
