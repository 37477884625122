import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Title } from 'components/Title/Title';
import { Text } from 'components/NewsletterSubscribe/NewsletterSubscribe.styles';

import {
  RemoveNewsletterSubscriberMutation,
  RemoveNewsletterSubscriberMutationVariables
} from './__graphql__/RemoveNewsletterSubscriberMutation';
import { removeNewsletterSubscriberMutation } from './api';

export const NewsletterUnsubscribe: React.FC = () => {
  const params = useParams<{ email: string; token: string }>();

  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  const [confirmNewsletterSubscriber] = useMutation<
    RemoveNewsletterSubscriberMutation,
    RemoveNewsletterSubscriberMutationVariables
  >(removeNewsletterSubscriberMutation, {
    onCompleted: () => setIsUnsubscribed(true),
    variables: params
  });

  useEffect(() => {
    void confirmNewsletterSubscriber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isUnsubscribed ? (
    <div>
      <Title>Successfully unsubscribed from our newsletter</Title>
      <Text>You won't receive any further newsletter emails from us.</Text>
    </div>
  ) : (
    <div />
  );
};
