import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import { Button } from 'components/Button/Button';
import { getFormattedClassEventDates } from 'utils';
import { Input } from 'components/Input/Input';
import { Message } from 'components/Message/Message';

import {
  Card,
  Header,
  Title,
  Root,
  Details,
  DetailsText,
  ScrollWrapper,
  Form,
  ClassPolicyLink
} from './BookModal.styles';
import { LocalClassesQuery_localClasses_events } from './__graphql__/LocalClassesQuery';
import { BookModalPayPalButton } from './BookModalPayPalButton';

export interface BookModalProps {
  close: () => void;
  event: LocalClassesQuery_localClasses_events;
  localClassName: string;
  refetchLocalClasses: () => Promise<void>;
}

export const BookModal: React.FC<BookModalProps> = ({
  close,
  event,
  localClassName,
  refetchLocalClasses
}) => {
  const formattedDates = getFormattedClassEventDates(event);
  const formRef = useRef<HTMLFormElement>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [personCount, setPersonCount] = useState(1);

  const content = (
    <Root>
      <Card>
        <Header>
          <Title>Book class</Title>
          <Button icon="close" onClick={close} />
        </Header>
        <Details>
          <DetailsText>{localClassName}</DetailsText>
          <DetailsText>{formattedDates}</DetailsText>
        </Details>
        <ScrollWrapper>
          <Form ref={formRef}>
            <Input
              label="First name"
              onChange={(event) => setFirstName(event.target.value)}
              required
              value={firstName}
            />
            <Input
              label="Last name"
              onChange={(event) => setLastName(event.target.value)}
              required
              value={lastName}
            />
            <Input
              hint="We will send a booking confirmation with location details to this email address."
              label="Email address"
              onChange={(event) => setEmail(event.target.value)}
              required
              type="email"
              value={email}
            />
            <Input
              label="Participants"
              max={event.maxStudentCount - event.reservedSpotsCount}
              min={1}
              onChange={(event) => setPersonCount(event.target.valueAsNumber)}
              required
              type="number"
              value={personCount}
            />
          </Form>
          <Message textSize="small">
            By booking this class you agree to our{' '}
            <ClassPolicyLink href="/class-policy" target="_blank">
              class policy
            </ClassPolicyLink>
            .
          </Message>
          <Message textSize="small" type="success">
            You can cancel your booking for a full refund up to one week before the class.
          </Message>
          <BookModalPayPalButton
            closeModal={close}
            email={email}
            event={event}
            firstName={firstName}
            isFormValid={formRef.current?.checkValidity() === true}
            lastName={lastName}
            personCount={personCount}
            refetchLocalClasses={refetchLocalClasses}
          />
        </ScrollWrapper>
      </Card>
    </Root>
  );

  return ReactDOM.createPortal(content, document.getElementById('overlays')!);
};
