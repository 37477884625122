import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { GlobalStyles } from 'GlobalStyles';
import { Header } from 'components/Header/Header';
import { StoreCheckoutComplete } from 'components/StoreCheckoutComplete/StoreCheckoutComplete';
import { Store } from 'components/Store/Store';
import fragmentTypes from 'fragmentTypes.json';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { NewsletterConfirm } from 'components/NewsletterConfirm/NewsletterConfirm';
import { NewsletterUnsubscribe } from 'components/NewsletterUnsubscribe/NewsletterUnsubscribe';
import { TermsOfService } from 'components/TermsOfService/TermsOfService';
import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';
import { ReturnPolicy } from 'components/ReturnPolicy/ReturnPolicy';
import { ShippingPolicy } from 'components/ShippingPolicy/ShippingPolicy';
import { Administration } from 'components/Administration/Administration';
import { AdministrationLogin } from 'components/AdministrationLogin/AdministrationLogin';
import { LocalClasses } from 'components/LocalClasses/LocalClasses';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ClassPolicy } from 'components/ClassPolicy/ClassPolicy';
import { LocalClassCheckoutComplete } from 'components/LocalClassCheckoutComplete copy/LocalClassCheckoutComplete';

import { Main, Root } from './App.styles';

export const App: React.FC = () => {
  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
    cache: new InMemoryCache({ possibleTypes: fragmentTypes.possibleTypes })
  });

  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <BrowserRouter>
          <GlobalStyles />
          <Root>
            <Header />
            <Switch>
              <Route path="/admin/login">
                <AdministrationLogin />
              </Route>
              <Route path="/admin">
                <Administration />
              </Route>
              <Route path="/">
                <Main>
                  <Switch>
                    <Route path="/store-checkout-complete">
                      <StoreCheckoutComplete />
                    </Route>
                    <Route path="/store">
                      <Store />
                    </Route>

                    <Route path="/classes-checkout-complete">
                      <LocalClassCheckoutComplete />
                    </Route>
                    <Route path="/classes">
                      <LocalClasses />
                    </Route>

                    <Route path="/newsletter/confirm/:email/:token">
                      <NewsletterConfirm />
                    </Route>
                    <Route path="/newsletter/unsubscribe/:email/:token">
                      <NewsletterUnsubscribe />
                    </Route>

                    <Route path="/terms-of-service">
                      <TermsOfService />
                    </Route>
                    <Route path="/privacy-policy">
                      <PrivacyPolicy />
                    </Route>
                    <Route path="/return-policy">
                      <ReturnPolicy />
                    </Route>
                    <Route path="/shipping-policy">
                      <ShippingPolicy />
                    </Route>
                    <Route path="/class-policy">
                      <ClassPolicy />
                    </Route>

                    <Redirect from="/shop/item/swordsmith-forge" to="/store/swordsmith-forge" />
                    <Redirect from="/shop/item/metalsmith-forge" to="/store/metalsmith-forge" />
                    <Redirect from="/shop/item/knifesmith-forge" to="/store/knifesmith-forge" />
                    <Redirect from="/shop/item/gas-burner" to="/store/propane-burner" />
                    <Redirect
                      from="/shop/item/gas-burner-with-regulator"
                      to="/store/propane-burner-with-regulator"
                    />
                    <Redirect
                      from="/shop/item/double-burner-assembly"
                      to="/store/double-burner-assembly"
                    />

                    <Redirect from="/" to="/store" />
                  </Switch>
                  <Sidebar />
                </Main>
              </Route>
            </Switch>
          </Root>
        </BrowserRouter>
      </ErrorBoundary>
    </ApolloProvider>
  );
};
