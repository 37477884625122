import React, { useMemo, useState } from 'react';
import { sortBy } from 'lodash';

import { ExpandableCard } from 'components/ExpandableCard/ExpandableCard';
import { ImageCarousel } from 'components/Store/ImageCarousel';
import { formatDate, formatTimeRange } from 'utils';
import { Button } from 'components/Button/Button';
import { Message } from 'components/Message/Message';
import { LocalClassAvailability } from 'components/LocalClassAvailability/LocalClassAvailability';

import {
  LocalClassesQuery_localClasses,
  LocalClassesQuery_localClasses_events
} from './__graphql__/LocalClassesQuery';
import {
  AvailabilityAndPrice,
  DetailsAndPrice,
  DescriptionLong,
  DescriptionShort,
  Event,
  EventList,
  EventPrice,
  EventSession,
  EventSessionDate,
  EventSessions,
  EventSessionTime,
  LocationAndNextClass,
  PriceAndActions,
  StyledPrice,
  Subheading,
  DescriptionShortAndHideDetails,
  StyledTitle
} from './LocalClassCard.styles';
import { BookModal } from './BookModal';

export interface LocalClassCardProps {
  localClass: LocalClassesQuery_localClasses;
  refetchLocalClasses: () => Promise<void>;
}

export const LocalClassCard: React.FC<LocalClassCardProps> = ({
  localClass,
  refetchLocalClasses
}) => {
  const [bookModalEvent, setBookModalEvent] = useState<LocalClassesQuery_localClasses_events>();

  const sortedEvents = useMemo(
    () =>
      sortBy(localClass.events, (event) => {
        const firstSession = sortBy(event.sessions, (session) => new Date(session.from))[0];
        return new Date(firstSession.from);
      }),
    [localClass.events]
  );

  const nextSessionDate = sortedEvents[0]?.sessions[0].from;

  return (
    <>
      {bookModalEvent && (
        <BookModal
          close={() => setBookModalEvent(undefined)}
          event={bookModalEvent}
          localClassName={localClass.name}
          refetchLocalClasses={refetchLocalClasses}
        />
      )}
      <ExpandableCard basePath="/classes" path={`/classes/${localClass.readableId}`}>
        {({ collapse, expand, isExpanded }) => (
          <>
            <ImageCarousel imageUrls={localClass.imageUrls} lightboxDisabled={!isExpanded} />
            <StyledTitle>{localClass.name}</StyledTitle>
            <DetailsAndPrice>
              <LocationAndNextClass>
                {localClass.location}
                {localClass.events.length > 0 && (
                  <> &bull; Next class {formatDate(nextSessionDate)}</>
                )}
              </LocationAndNextClass>
              <PriceAndActions>
                {sortedEvents[0] !== undefined && (
                  <StyledPrice hideZeroFractionDigits value={sortedEvents[0].price} />
                )}
              </PriceAndActions>
            </DetailsAndPrice>
            <DescriptionShortAndHideDetails>
              <DescriptionShort>{localClass.descriptionShort}</DescriptionShort>
              <Button
                icon={isExpanded ? 'chevronUp' : 'chevronDown'}
                onClick={() => {
                  isExpanded ? collapse() : expand();
                }}
                text={isExpanded ? 'Hide details' : 'Show details'}
              />
            </DescriptionShortAndHideDetails>
            {isExpanded && (
              <>
                <Subheading>Description</Subheading>
                <DescriptionLong>{localClass.descriptionLong}</DescriptionLong>
                <Subheading>Schedule</Subheading>
                {localClass.events.length === 0 ? (
                  <Message>There are currently no scheduled classes.</Message>
                ) : (
                  <EventList>
                    {sortedEvents.map((event) => {
                      const areNoSpotsLeft = event.reservedSpotsCount === event.maxStudentCount;

                      return (
                        <Event key={event.id}>
                          <EventSessions>
                            {event.sessions.map((session) => (
                              <EventSession key={session.id}>
                                <EventSessionDate>{formatDate(session.from)}</EventSessionDate>
                                <EventSessionTime>
                                  {formatTimeRange(session.from, session.to)}
                                </EventSessionTime>
                              </EventSession>
                            ))}
                          </EventSessions>
                          <AvailabilityAndPrice>
                            <EventPrice hideZeroFractionDigits value={event.price} />
                            <LocalClassAvailability event={event} hideCount />
                          </AvailabilityAndPrice>
                          <Button
                            disabled={areNoSpotsLeft}
                            icon="arrowRight"
                            onClick={() => setBookModalEvent(event)}
                            text="Book"
                            type="primary"
                          />
                        </Event>
                      );
                    })}
                  </EventList>
                )}
              </>
            )}
          </>
        )}
      </ExpandableCard>
    </>
  );
};
