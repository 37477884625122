import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PayPal } from 'types/paypal';
import { useMutation } from '@apollo/client';
import { groupBy } from 'lodash';

import { WASHINGTON_STATE_SALES_TAX } from 'constants/store';
import { ShoppingCartContext } from 'contexts/ShoppingCartContext';
import { Button } from 'components/Button/Button';
import { PayPalButton } from 'components/PayPalButton/PayPalButton';
import { Message } from 'components/Message/Message';
import { Input } from 'components/Input/Input';
import { RadioButtonGroup } from 'components/RadioButtonGroup/RadioButtonGroup';
import { roundPrice } from 'utils';

import {
  Root,
  Card,
  Header,
  HeaderShoppingCartIcon,
  HeaderText,
  Body,
  Items,
  Image,
  ProductName,
  Quantity,
  QuantitySymbol,
  ItemPrice,
  Divider,
  TotalPrices,
  TotalPriceLabel,
  TotalPriceValue,
  Footer,
  FooterShoppingCartIcon,
  ItemsSummary,
  Subtotal
} from './ShoppingCart.styles';
import { completeOrderMutation, createOrderMutation } from './api';
import {
  CompleteOrderMutation,
  CompleteOrderMutationVariables
} from './__graphql__/CompleteOrderMutation';
import {
  CreateOrderMutation,
  CreateOrderMutationVariables
} from './__graphql__/CreateOrderMutation';

export const ShoppingCart: React.FC = () => {
  const history = useHistory();

  const {
    isWashingtonStateResident,
    items,
    refetchProducts,
    removeItem,
    reset,
    setIsWashingtonStateResident,
    updateItemQuantity,
    wholesaleCustomerId
  } = useContext(ShoppingCartContext);

  const [isOpen, setIsOpen] = useState(false);

  const [isShowingPaymentError, setIsShowingPaymentError] = useState(false);
  const [isShowingTaxPromptError, setIsShowingTaxPromptError] = useState(false);

  const handlePayPalButtonClick = useCallback(() => {
    setIsShowingPaymentError(false);

    if (isWashingtonStateResident !== null) return;
    setIsShowingTaxPromptError(true);
  }, [isWashingtonStateResident]);

  const [completeOrder] = useMutation<CompleteOrderMutation, CompleteOrderMutationVariables>(
    completeOrderMutation,
    {
      refetchQueries: ['StoreProductsQuery'],
      awaitRefetchQueries: true
    }
  );

  const [createOwnOrder] = useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    createOrderMutation
  );

  const handleCompleteOrder = useCallback(async (orderId: string) => {
    await completeOrder({ variables: { id: orderId } });

    reset();
    history.push('/store-checkout-complete');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateOrder = useCallback(async () => {
    if (isWashingtonStateResident === null) {
      throw new Error('Unable to create order because `isWashingtonStateResident` is `null`.');
    }

    const response = await createOwnOrder({
      variables: {
        input: {
          isWashingtonStateResident,
          items: items.map((item) => {
            if (item.quantity === null) {
              throw new Error('Unable to create order because `item.quantity` is `null`.');
            }

            return {
              productId: item.product.id,
              quantity: item.quantity,
              size: item.size
            };
          }),
          wholesaleCustomerId
        }
      }
    });

    if (response.data === null || response.data === undefined) {
      throw new Error('Create order response is empty.');
    }

    return response.data.createStoreOrder.payPalOrderId;
  }, [createOwnOrder, isWashingtonStateResident, items, wholesaleCustomerId]);

  const handlePayPalError = useCallback(() => {
    setIsShowingPaymentError(true);
    void refetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShippingChange = useCallback<PayPal.OnShippingChange>(
    ({ shipping_address }, actions) => {
      if (shipping_address.country_code !== 'US') {
        actions.reject();
      }

      const state = shipping_address.state.toUpperCase();

      if (isWashingtonStateResident === true && state !== 'WA') {
        actions.reject();
      }

      if (isWashingtonStateResident === false && state === 'WA') {
        actions.reject();
      }

      actions.resolve();
    },
    [isWashingtonStateResident]
  );

  const subtotal = items.reduce((total, { product: { price, wholesaleDiscount }, quantity }) => {
    const wholesaleDiscountToApply = wholesaleCustomerId !== null ? wholesaleDiscount : 0;
    return total + (quantity ?? 0) * roundPrice(price * (1 - wholesaleDiscountToApply));
  }, 0);
  const taxTotal =
    isWashingtonStateResident === true ? roundPrice(WASHINGTON_STATE_SALES_TAX * subtotal) : 0;

  const itemsByProductId = groupBy(items, 'product.id');
  const itemsByProduct = Object.entries(itemsByProductId).map(([, productItems]) => ({
    product: productItems[0].product,
    items: productItems,
    totalQuantity: productItems.reduce((total, item) => total + (item.quantity ?? 0), 0)
  }));

  const shippingTotal = itemsByProduct.reduce(
    (total, { product: { itemsPerPackage, shippingCost }, totalQuantity }) => {
      const adjustedShippingCost = Math.ceil(totalQuantity / itemsPerPackage) * shippingCost;
      return total + adjustedShippingCost;
    },
    0
  );

  const areAllQuantitiesValid = itemsByProduct.every(
    ({ product, totalQuantity }) => totalQuantity >= 1 && totalQuantity <= product.availableAmount
  );
  const isFormValid = areAllQuantitiesValid && isWashingtonStateResident !== null;

  return (
    <Root $isOpen={isOpen}>
      <Card $isOpen={isOpen}>
        {isOpen && (
          <>
            <Body $isShowingPaymentError={isShowingPaymentError}>
              <Header>
                <HeaderShoppingCartIcon />
                <HeaderText>Shopping Cart</HeaderText>
                <Button icon="close" onClick={() => setIsOpen(false)} />
              </Header>
              {isShowingPaymentError && (
                <Message title="Checkout failed" type="error">
                  An error occured during checkout. Please try again or contact customer service.
                </Message>
              )}
              <Message
                title="Is your shipping address in the State of Washington?"
                type={
                  isShowingTaxPromptError && isWashingtonStateResident === null
                    ? 'error'
                    : 'default'
                }
              >
                <RadioButtonGroup<'yes' | 'no' | null>
                  onChange={(newValue) => setIsWashingtonStateResident(newValue === 'yes')}
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' }
                  ]}
                  value={
                    isWashingtonStateResident === null
                      ? null
                      : isWashingtonStateResident === true
                      ? 'yes'
                      : 'no'
                  }
                />
              </Message>
              <Items>
                {items.map((item) => {
                  const otherSizesQuantity = items
                    .filter(
                      (otherItem) =>
                        otherItem.product.id === item.product.id && otherItem.size !== item.size
                    )
                    .reduce((total, item) => total + (item.quantity ?? 0), 0);

                  const availableAmount = item.product.availableAmount - otherSizesQuantity;

                  return (
                    <React.Fragment key={`${item.product.id}${item.size}`}>
                      <Image src={item.product.imageUrls[0]} />
                      <ProductName>
                        {item.product.name}
                        {item.size !== undefined && ` (Size: ${item.size})`}
                      </ProductName>
                      <Quantity>
                        <Input
                          max={availableAmount}
                          min="1"
                          onChange={(event) =>
                            updateItemQuantity(
                              item.product.id,
                              isNaN(event.target.valueAsNumber) ? null : event.target.valueAsNumber,
                              item.size
                            )
                          }
                          required
                          type="number"
                          value={item.quantity ?? ''}
                        />
                        <QuantitySymbol>x</QuantitySymbol>
                      </Quantity>
                      <Button
                        icon="trashCan"
                        onClick={() => removeItem(item.product.id, item.size)}
                      />
                      <ItemPrice
                        discount={wholesaleCustomerId !== null ? item.product.wholesaleDiscount : 0}
                        value={(item.quantity ?? 0) * item.product.price}
                      />
                    </React.Fragment>
                  );
                })}
              </Items>
              <Divider />
              <TotalPrices>
                <TotalPriceLabel>Subtotal</TotalPriceLabel>
                <TotalPriceValue value={subtotal} />
                {taxTotal > 0 && (
                  <>
                    <TotalPriceLabel>Tax</TotalPriceLabel>
                    <TotalPriceValue value={taxTotal} />
                  </>
                )}
                <TotalPriceLabel>Shipping</TotalPriceLabel>
                <TotalPriceValue value={shippingTotal} />
                <TotalPriceLabel>Total</TotalPriceLabel>
                <TotalPriceValue value={subtotal + taxTotal + shippingTotal} />
              </TotalPrices>
            </Body>
          </>
        )}
        <Footer $isOpen={isOpen}>
          {isOpen ? (
            <>
              <Button
                icon="chevronDown"
                onClick={() => setIsOpen(false)}
                text="Close shopping cart"
              />
              <PayPalButton
                disabled={!isFormValid}
                onClick={handlePayPalButtonClick}
                onCompleteOrder={handleCompleteOrder}
                onCreateOrder={handleCreateOrder}
                onError={handlePayPalError}
                onShippingChange={onShippingChange}
              />
            </>
          ) : (
            <>
              <FooterShoppingCartIcon />
              <ItemsSummary>
                {items
                  .map(
                    ({ product: { name }, quantity, size }) =>
                      `${quantity}x ${name}${size === undefined ? '' : ` (Size: ${size})`}`
                  )
                  .join(', ')}
              </ItemsSummary>
              <Subtotal value={subtotal} />
              <Button
                icon="chevronUp"
                onClick={() => setIsOpen(true)}
                text="Open shopping cart"
                type="primary"
              />
            </>
          )}
        </Footer>
      </Card>
    </Root>
  );
};
