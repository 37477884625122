import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { ShoppingCartContext } from 'contexts/ShoppingCartContext';
import { ShoppingCart } from 'components/ShoppingCart/ShoppingCart';
import { useShoppingCartManager } from 'hooks/useShoppingCartManager/useShoppingCartManager';
import { Message } from 'components/Message/Message';

import { storeProductsQuery } from './api';
import { StoreProductsQuery } from './__graphql__/StoreProductsQuery';
import { Root } from './Store.styles';
import { ProductCard } from './ProductCard';

export const Store: React.FC = () => {
  const location = useLocation();

  const [wholesaleCustomerId, setWholesaleCustomerId] = useState<string | null>(null);

  useEffect(() => {
    const queryParamId = new URLSearchParams(location.search).get('wholesaleCustomerId');
    setWholesaleCustomerId(queryParamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = useQuery<StoreProductsQuery>(storeProductsQuery);

  const shoppingCartManager = useShoppingCartManager({ wholesaleCustomerId });
  return (
    <ShoppingCartContext.Provider value={shoppingCartManager}>
      <Root>
        {wholesaleCustomerId !== null && (
          <Message title="Showing wholesale prices" type="success" />
        )}
        {result.data?.products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
        {shoppingCartManager.items.length > 0 && <ShoppingCart />}
      </Root>
    </ShoppingCartContext.Provider>
  );
};
