import React from 'react';

import { Root, CheckIcon, Title, Text } from './StoreCheckoutComplete.styles';

export const StoreCheckoutComplete: React.FC = () => {
  return (
    <Root>
      <CheckIcon />
      <Title>Thank you, we received your order</Title>
      <Text>
        You will get a confirmation email from PayPal.
        <br />
        Please allow 5-10 business days for your items to arrive. The tracking number is provided
        via PayPal for some but not all products. Please contact us if you can't see your tracking
        number on PayPal.
      </Text>
    </Root>
  );
};
