import styled from 'styled-components';

import { colors } from 'styles/colors';
import { mixins, TextSize } from 'styles/mixins';

interface Props {
  $textSize?: TextSize;
  $type?: 'default' | 'error' | 'success';
}

const getBackgroundColorForType = (options: Props) => {
  const colorForType = {
    default: colors.lightBackground,
    error: colors.errorBackground,
    success: colors.successBackground
  } as const;

  return options.$type === undefined ? colorForType.default : colorForType[options.$type];
};

const getBorderColorForType = (options: Props) => {
  const colorForType = {
    default: colors.control,
    error: colors.error,
    success: colors.success
  } as const;

  return options.$type === undefined ? colorForType.default : colorForType[options.$type];
};

const getTextColorForType = (options: Props) => {
  const colorForType = {
    default: colors.text,
    error: colors.error,
    success: colors.success
  } as const;

  return options.$type === undefined ? colorForType.default : colorForType[options.$type];
};

export const Root = styled.div<Props>`
  background-color: ${getBackgroundColorForType};
  border-radius: 8px;
  border: 2px solid ${getBorderColorForType};
  display: grid;
  grid-gap: 6px;
  grid-template-columns: 100%;
  padding: 8px;
  width: 100%;
`;

export const Title = styled.div<Props>`
  ${mixins.text({ isHeading: true })}

  color: ${getTextColorForType};
  line-height: 1.4;
`;

export const Body = styled.div<Props>`
  ${({ $textSize }) => mixins.text({ size: $textSize })}

  color: ${getTextColorForType};
`;
