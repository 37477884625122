import gql from 'graphql-tag';

export const completeOrderMutation = gql`
  mutation CompleteOrderMutation($id: String!) {
    completeStoreOrder(id: $id)
  }
`;

export const createOrderMutation = gql`
  mutation CreateOrderMutation($input: CreateStoreOrderInput!) {
    createStoreOrder(input: $input) {
      payPalOrderId
    }
  }
`;
