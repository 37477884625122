export const CLASS_TESTIMONIALS = [
  {
    authorName: 'Lavonne',
    text:
      'I really enjoyed this beginner blacksmithing class. Mike is an excellent teacher, and the overall experience was great.'
  },
  {
    authorName: 'Felicia G',
    text:
      "I took the Beginner's Blacksmithing Weekend course and I could not be happier with what I gained from Mike's teaching and expertise! He's very knowledgeable and highly skilled, and the atmosphere was very collaborative. It was terrific to meet and work with other smiths of a similar experience level and hone our skills as a group. In addition, his short lectures and diagrams really helped me know my steel and how to work with it better - if you're even remotely interested in smithing, you owe it to yourself to take a course with Mike!"
  },
  {
    authorName: 'John Cook',
    text:
      'I took the beginning blacksmithing class and found Mike to be very knowledgeable. He was very quick to answer questions and give demonstration when needed. I feel that taking this class put me ahead of the learning curve as opposed to trying to learn on my own or just watching videos. I have already signed up for the advanced class.'
  },
  {
    authorName: 'jay360',
    text:
      "Can't say enough about Mike's expert knowledge and experience around the Forge. He takes the time to explain and diagram each step of the forging process as well as hands on when you need assistance. I walked away much more confident and knowledgeable and I had 3 finished projects to show and tell. 5 star rating and you can't go wrong with Mike."
  }
] as const;
