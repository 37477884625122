import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconName } from 'components/Icon/Icon';
import { useIsMobile } from 'hooks/useIsMobile';

import { Root, Text, StyledIcon } from './NavigationItem.styles';

export interface NavigationItemProps {
  icon: IconName;
  path: string;
  text: string;
}

export const NavigationItem: React.FC<NavigationItemProps> = ({ icon, path, text }) => {
  const history = useHistory();

  const getIsCurrentPath = useCallback(
    (location = window.location) => location.pathname.startsWith(path),
    [path]
  );
  const [isActive, setIsActive] = useState(getIsCurrentPath(location));

  history.listen((location) => {
    setIsActive(getIsCurrentPath(location));
  });

  const isMobile = useIsMobile();

  return (
    <Root $isActive={isActive} $isMobile={isMobile} to={path}>
      <StyledIcon $isActive={isActive} $isMobile={isMobile} name={icon} />
      <Text $isActive={isActive} $isMobile={isMobile}>
        {text}
      </Text>
    </Root>
  );
};
