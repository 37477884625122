export const colors = {
  control: 'rgb(235 235 235)',
  controlText: 'rgba(0 0 0 / 85%)',
  error: 'rgb(211 47 47)',
  errorBackground: 'rgb(211 47 47 / 10%)',
  lightBackground: 'rgba(0 0 0 / 2%)',
  primary: 'rgb(24 117 209)',
  primaryText: 'rgba(255 255 255 / 95%)',
  success: 'rgb(56 142 60)',
  successBackground: 'rgba(56 142 60 / 10%)',
  text: 'rgb(25 25 25)',
  textHelper: 'rgb(100 100 100)',
  white: 'white'
};
