import React from 'react';

import { Root } from './Icon.styles';
import { ReactComponent as Add } from './icons/Add.svg';
import { ReactComponent as Air } from './icons/Air.svg';
import { ReactComponent as ArrowRight } from './icons/ArrowRight.svg';
import { ReactComponent as Briefcase } from './icons/Briefcase.svg';
import { ReactComponent as Certified } from './icons/Certified.svg';
import { ReactComponent as CheckCircle } from './icons/CheckCircle.svg';
import { ReactComponent as ChevronDown } from './icons/ChevronDown.svg';
import { ReactComponent as ChevronLeft } from './icons/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from './icons/ChevronRight.svg';
import { ReactComponent as ChevronUp } from './icons/ChevronUp.svg';
import { ReactComponent as Circle } from './icons/Circle.svg';
import { ReactComponent as Close } from './icons/Close.svg';
import { ReactComponent as DimensionsCube } from './icons/DimensionsCube.svg';
import { ReactComponent as DimensionsRectangle } from './icons/DimensionsRectangle.svg';
import { ReactComponent as Doors } from './icons/Doors.svg';
import { ReactComponent as Drawer } from './icons/Drawer.svg';
import { ReactComponent as ErlenmeyerFlask } from './icons/ErlenmeyerFlask.svg';
import { ReactComponent as Fire } from './icons/Fire.svg';
import { ReactComponent as Fireplace } from './icons/Fireplace.svg';
import { ReactComponent as FlipCamera } from './icons/FlipCamera.svg';
import { ReactComponent as Length } from './icons/Length.svg';
import { ReactComponent as MountingHoles } from './icons/MountingHoles.svg';
import { ReactComponent as PayPal } from './icons/PayPal.svg';
import { ReactComponent as Rectangle } from './icons/Rectangle.svg';
import { ReactComponent as RoundedCorner } from './icons/RoundedCorner.svg';
import { ReactComponent as Settings } from './icons/Settings.svg';
import { ReactComponent as School } from './icons/School.svg';
import { ReactComponent as ShoppingCart } from './icons/ShoppingCart.svg';
import { ReactComponent as ShoppingCartAdd } from './icons/ShoppingCartAdd.svg';
import { ReactComponent as Store } from './icons/Store.svg';
import { ReactComponent as Strength } from './icons/Strength.svg';
import { ReactComponent as Square } from './icons/Square.svg';
import { ReactComponent as Table } from './icons/Table.svg';
import { ReactComponent as Thickness } from './icons/Thickness.svg';
import { ReactComponent as TrashCan } from './icons/TrashCan.svg';
import { ReactComponent as Weight } from './icons/Weight.svg';

const icons = {
  add: Add,
  air: Air,
  arrowRight: ArrowRight,
  briefcase: Briefcase,
  certified: Certified,
  checkCircle: CheckCircle,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  circle: Circle,
  close: Close,
  dimensionsCube: DimensionsCube,
  dimensionsRectangle: DimensionsRectangle,
  doors: Doors,
  drawer: Drawer,
  erlenmeyerFlask: ErlenmeyerFlask,
  fire: Fire,
  fireplace: Fireplace,
  flipCamera: FlipCamera,
  length: Length,
  mountingHoles: MountingHoles,
  payPal: PayPal,
  rectangle: Rectangle,
  roundedCorner: RoundedCorner,
  school: School,
  settings: Settings,
  shoppingCart: ShoppingCart,
  shoppingCartAdd: ShoppingCartAdd,
  store: Store,
  strength: Strength,
  square: Square,
  table: Table,
  thickness: Thickness,
  trashCan: TrashCan,
  weight: Weight
};

export type IconName = keyof typeof icons;

export interface IconProps {
  className?: string;
  color?: string;
  name: IconName;
}

export const Icon: React.FC<IconProps> = ({ className, color, name }) => {
  const IconComponent = icons[name];
  if (IconComponent === undefined) throw new Error(`Invalid icon name "${name}".`);

  return (
    <Root $color={color} className={className}>
      <IconComponent />
    </Root>
  );
};
