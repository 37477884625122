import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { Input } from 'components/Input/Input';
import { Message } from 'components/Message/Message';

import { Root, SubscribeButton, Text, Title } from './NewsletterSubscribe.styles';
import { addNewsletterSubscriberMutation } from './api';
import {
  AddNewsletterSubscriberMutation,
  AddNewsletterSubscriberMutationVariables
} from './__graphql__/AddNewsletterSubscriberMutation';

export const NewsletterSubscribe: React.FC = () => {
  const [email, setEmail] = useState<string>('');

  const [isShowingError, setIsShowingError] = useState(false);
  const [isShowingSuccess, setIsShowingSuccess] = useState(false);

  const [addNewsletterSubscriber] = useMutation<
    AddNewsletterSubscriberMutation,
    AddNewsletterSubscriberMutationVariables
  >(addNewsletterSubscriberMutation, {
    variables: { email: email as string }
  });

  return (
    <Root>
      <Title>Newsletter</Title>
      <Text>Subscribe to get occasional updates about new products and special offers.</Text>
      {isShowingError && (
        <Message title="Unable to subscribe to newsletter" type="error">
          Make sure the email address is valid and that you're not already subscribed. Otherwise,
          try again later.
        </Message>
      )}
      {isShowingSuccess && (
        <Message title="We just sent you a confirmation email" type="success">
          Please click the link in the email to confirm your newsletter subscription.
        </Message>
      )}
      <Input
        label="Your email address"
        onChange={(event) => setEmail(event.target.value)}
        type="email"
        value={email}
      />
      <SubscribeButton
        disabled={email === undefined || email.length === 0}
        onClick={async () => {
          if (email === undefined) return;

          setIsShowingError(false);
          setIsShowingSuccess(false);

          try {
            await addNewsletterSubscriber();

            setIsShowingSuccess(true);
            setEmail('');
          } catch {
            setIsShowingError(true);
          }
        }}
        icon="add"
        text="Subscribe"
        type="primary"
      />
    </Root>
  );
};
