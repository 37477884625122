import React from 'react';

import { ShoppingCartManager } from 'hooks/useShoppingCartManager/useShoppingCartManager';

export const ShoppingCartContext = React.createContext<ShoppingCartManager>({
  isWashingtonStateResident: null,
  items: [],
  wholesaleCustomerId: null,
  addItem: () => {},
  refetchProducts: () => Promise.resolve(),
  removeItem: () => {},
  reset: () => {},
  setIsWashingtonStateResident: () => {},
  updateItemQuantity: () => {}
});
