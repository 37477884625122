import styled from 'styled-components';

import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

export const Root = styled.div`
  ${mixins.card()}

  max-width: 512px;
  width: 100%;
`;

export const Classes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const Events = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DatesAndAvailability = styled.div`
  align-items: baseline;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: max-content 1fr max-content;
`;

export const Divider = styled.div`
  background: ${colors.control};
  height: 1px;
  width: 100%;
`;

export const Orders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
`;

export const Order = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const NameAndEmail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Name = styled.div`
  ${mixins.text({ isHeading: true, size: 'small' })}

  color: ${colors.textHelper};
`;

export const Email = styled.div`
  ${mixins.text({ size: 'extraSmall' })}
`;
