import React from 'react';

import { Title } from 'components/Title/Title';
import { Paragraph } from 'components/Paragraph/Paragraph';

export const ClassPolicy: React.FC = () => {
  return (
    <div>
      <Title>Class Policy</Title>
      <Paragraph>
        <p>CANCELLATIONS</p>
        <p>
          If you wish to cancel your class booking, please contact us at{' '}
          <a href="mailto:service@mathewsonmetals.com" target="_blank">
            service@mathewsonmetals.com
          </a>
          .
        </p>
        <p>
          We fully refund bookings canceled up to one week before the class. Sometimes we have to
          cancel a class, in which case you will always receive a full refund.
        </p>
        <p>AGE REQUIREMENTS</p>
        <p>
          Our classes are for adults aged 18 or older. Children between 14 and 17 can participate in
          classes only if accompanied by a supervising adult who is also participating.
        </p>
      </Paragraph>
    </div>
  );
};
