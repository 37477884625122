import styled from 'styled-components';

interface RootProps {
  $color?: string;
}

export const Root = styled.div<RootProps>`
  height: 24px;
  width: 24px;

  svg {
    height: 100%;
    width: 100%;

    path {
      fill: ${({ $color }) => ($color === undefined ? 'black' : $color)};
    }
  }
`;
