import React from 'react';
import { useLocation } from 'react-router-dom';

import { NewsletterSubscribe } from 'components/NewsletterSubscribe/NewsletterSubscribe';
import { Title } from 'components/Title/Title';
import { Message } from 'components/Message/Message';

import {
  ClassTestimonial,
  ClassTestimonialAuthorName,
  ClassTestimonialText,
  Footer,
  FooterExternalLink,
  FooterLink,
  MessageWrapper
} from './Sidebar.styles';
import { CLASS_TESTIMONIALS } from './constants';

export const Sidebar: React.FC = () => {
  const location = useLocation();

  return (
    <div>
      {location.pathname.startsWith('/classes') && (
        <div>
          <MessageWrapper>
            <Message title="Private group events" type="default">
              Contact us at{' '}
              <a href="mailto:service@mathewsonmetals.com" target="_blank">
                service@mathewsonmetals.com
              </a>{' '}
              to book a private class. We offer discounts for groups of 4 or more.
            </Message>
            <Message title="10% military discount" type="default">
              Active duty service members and veterans can send us their military ID after booking a
              class for a 10% refund.
            </Message>
          </MessageWrapper>
          <Title>Class testimonials</Title>
          {CLASS_TESTIMONIALS.map(({ authorName, text }) => (
            <ClassTestimonial>
              <ClassTestimonialText>{text}</ClassTestimonialText>
              <ClassTestimonialAuthorName>{authorName}</ClassTestimonialAuthorName>
            </ClassTestimonial>
          ))}
        </div>
      )}
      <NewsletterSubscribe />
      <Footer>
        <FooterExternalLink href="mailto:service@mathewsonmetals.com" target="_blank">
          Contact us
        </FooterExternalLink>
        <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
        <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
        <FooterLink to="/return-policy">Return Policy</FooterLink>
        <FooterLink to="/shipping-policy">Shipping Policy</FooterLink>
        {process.env.REACT_APP_CLASSES_ENABLED === 'true' && (
          <FooterLink to="/class-policy">Class Policy</FooterLink>
        )}
      </Footer>
    </div>
  );
};
