import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';

import { PayPalButton } from 'components/PayPalButton/PayPalButton';
import { Message } from 'components/Message/Message';

import { LocalClassesQuery_localClasses_events } from './__graphql__/LocalClassesQuery';
import {
  CompleteLocalClassOrderMutation,
  CompleteLocalClassOrderMutationVariables
} from './__graphql__/CompleteLocalClassOrderMutation';
import { completeOrderMutation, createOrderMutation } from './api';
import {
  CreateLocalClassOrderMutation,
  CreateLocalClassOrderMutationVariables
} from './__graphql__/CreateLocalClassOrderMutation';

export interface BookModalPayPalButtonProps {
  closeModal: () => void;
  email: string;
  event: LocalClassesQuery_localClasses_events;
  firstName: string;
  isFormValid: boolean;
  lastName: string;
  personCount: number;
  refetchLocalClasses: () => Promise<void>;
}

export const BookModalPayPalButton: React.FC<BookModalPayPalButtonProps> = ({
  closeModal,
  email,
  event,
  firstName,
  isFormValid,
  lastName,
  personCount,
  refetchLocalClasses
}) => {
  const history = useHistory();

  const [isShowingError, setIsShowingError] = useState(false);

  const handleClick = useCallback(() => {
    setIsShowingError(false);
  }, []);

  const [completeOrder] = useMutation<
    CompleteLocalClassOrderMutation,
    CompleteLocalClassOrderMutationVariables
  >(completeOrderMutation, {
    refetchQueries: ['LocalClassesQuery'],
    awaitRefetchQueries: true
  });

  const [createOrder] = useMutation<
    CreateLocalClassOrderMutation,
    CreateLocalClassOrderMutationVariables
  >(createOrderMutation);

  const handleCompleteOrder = useCallback(
    async (payPalOrderId: string) => {
      await completeOrder({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            payPalOrderId
          }
        }
      });

      closeModal();
      history.push('/classes-checkout-complete');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, firstName, lastName]
  );

  const handleCreateOrder = useCallback(async () => {
    const response = await createOrder({
      variables: {
        input: {
          eventId: event.id,
          personCount
        }
      }
    });

    if (response.data === null || response.data === undefined) {
      throw new Error('Create order response is empty.');
    }

    return response.data.createLocalClassOrder.payPalOrderId;
  }, [createOrder, event, personCount]);

  const handleError = useCallback(() => {
    setIsShowingError(true);
    void refetchLocalClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isShowingError && (
        <Message title="Checkout failed" type="error">
          An error occured during checkout. Please try again or contact customer service.
        </Message>
      )}
      <PayPalButton
        onClick={handleClick}
        onCompleteOrder={handleCompleteOrder}
        onCreateOrder={handleCreateOrder}
        disabled={!isFormValid}
        onError={handleError}
      />
    </>
  );
};
