import React, { useCallback, useEffect, useRef } from 'react';

export const useResize = ({
  fixedElement,
  onResize
}: {
  fixedElement?: HTMLElement;
  onResize: (contentRect: DOMRectReadOnly) => void;
}): { ref: React.RefCallback<HTMLElement | null> } => {
  const resizeObserver = useRef(
    new ResizeObserver((entries) => {
      onResize(entries[0].contentRect);
    })
  );

  const elementRef = useCallback((element: HTMLElement | null) => {
    resizeObserver.current.disconnect();

    if (element === null) return;

    resizeObserver.current.observe(element);
  }, []);

  useEffect(() => {
    if (!fixedElement) return;

    elementRef(fixedElement);
  }, [elementRef, fixedElement]);

  return { ref: elementRef };
};
