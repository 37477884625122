/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ANALYTICS_EVENT {
  googleAds = "googleAds",
  theRanger = "theRanger",
}

export interface CompleteLocalClassOrderInput {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly payPalOrderId: string;
}

export interface CreateLocalClassOrderInput {
  readonly eventId: string;
  readonly personCount: number;
}

export interface CreateStoreOrderInput {
  readonly isWashingtonStateResident: boolean;
  readonly items: ReadonlyArray<CreateStoreOrderItemInput>;
  readonly wholesaleCustomerId?: string | null;
}

export interface CreateStoreOrderItemInput {
  readonly productId: string;
  readonly quantity: number;
  readonly size?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
