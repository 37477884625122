import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';
import { mediaQueries } from 'styles/mediaQueries';

interface BaseProps {
  $type?: 'default' | 'primary';
}

interface RootProps extends BaseProps {
  $hasText: boolean;
}

export const Root = styled.button<RootProps>`
  align-items: center;
  background-color: ${({ $type }) => ($type === 'primary' ? colors.primary : colors.control)};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 40px;
  justify-content: center;
  outline: none;
  padding: 0 12px 0 8px;
  position: relative;

  &:disabled {
    cursor: default;
    filter: grayscale(1);
    opacity: 0.5;
  }

  ${({ $hasText }) =>
    !$hasText &&
    css`
      border-radius: 50%;
      padding: 8px;
      width: 40px;

      @media ${mediaQueries.mobile} {
        width: 48px;
      }
    `}

  @media ${mediaQueries.mobile} {
    height: 48px;
  }
`;

export const DropdownTrigger = styled.select`
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const StyledIcon = styled(Icon).attrs<BaseProps>(({ $type }) => ({
  color: $type === 'primary' ? colors.primaryText : colors.controlText
}))<BaseProps>``;

export const Text = styled.div<BaseProps>`
  ${mixins.text({ isHeading: true, size: 'small' })}

  color: ${({ $type }) => ($type === 'primary' ? colors.primaryText : colors.controlText)};
`;
