import React from 'react';

import { Root, CheckIcon, Title, Text } from './LocalClassCheckoutComplete.styles';

export const LocalClassCheckoutComplete: React.FC = () => {
  return (
    <Root>
      <CheckIcon />
      <Title>Thank you, we received your booking</Title>
      <Text>
        You will get a payment confirmation email from PayPal and a booking confirmation email from
        us. The booking confirmation will contain the location details for the class. See you soon!
      </Text>
    </Root>
  );
};
