import styled from 'styled-components';

import { Button } from 'components/Button/Button';
import { mediaQueries } from 'styles/mediaQueries';

export const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 280px;
  width: 100%;

  @media ${mediaQueries.mobile} {
    max-width: 100%;
  }
`;

export const LogInButton = styled(Button)`
  width: 100%;
`;
