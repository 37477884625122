import gql from 'graphql-tag';

export const shoppingCartProductsQuery = gql`
  query ShoppingCartProductsQuery {
    products {
      availableAmount
      id
      imageUrls
      itemsPerPackage
      name
      price
      readableId
      shippingCost
      wholesaleDiscount
    }
  }
`;
