import styled from 'styled-components';

import { colors } from 'styles/colors';
import { mediaQueries } from 'styles/mediaQueries';
import { mixins } from 'styles/mixins';

export const Root = styled.div`
  display: flex;
  gap: 16px;
`;

export const RadioButtonWrapper = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;

  @media ${mediaQueries.mobile} {
    padding: 8px 8px 8px 0;
  }
`;

export const RadioButton = styled.input`
  appearance: none;
  border: none;
  height: 20px;
  margin: 0;
  width: 20px;

  &::before {
    background-color: ${colors.white};
    border: 2px solid ${colors.control};
    border-radius: 50%;
    cursor: pointer;
    display: block;
    content: '';
    height: 100%;
    width: 100%;
  }

  &:checked {
    &::before {
      border: 6px solid ${colors.primary};
    }
  }
`;

export const Label = styled.div`
  ${mixins.text()}
`;
