import styled from 'styled-components';

import { mixins } from 'styles/mixins';

import logoUrl from './Logo.svg';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;
`;

export const PromoSection = styled.div`
  align-items: center;
  display: flex;
`;

export const TheRangerPromo = styled.div`
  ${mixins.text()}

  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 4px;
`;

export const TheRangerLogo = styled.img`
  height: 32px;
`;

export const Logo = styled.img.attrs({ src: logoUrl })`
  margin-top: 8px;
  width: 248px;
`;

export const TrustpilotWidget = styled.div`
  height: 24px;
  margin-top: 16px;
  overflow: hidden;
`;
