import styled from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';
import { mediaQueries } from 'styles/mediaQueries';
import { Price } from 'components/Price/Price';

export const Body = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: auto auto;
  margin-top: 20px;
`;

export const Name = styled.div`
  ${mixins.text({
    isHeading: true,
    size: 'extraLarge'
  })}
`;

export const DescriptionShort = styled.div`
  ${mixins.text()}

  margin-top: 12px;

  @media ${mediaQueries.mobile} {
    margin-top: 8px;
  }
`;

export const PriceAndAvailability = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

export const StyledPrice = styled(Price)`
  ${mixins.text({
    isHeading: true,
    size: 'extraLarge'
  })}
`;

interface AvailabilityProps {
  $isAvailable: boolean;
}

export const Availability = styled.div<AvailabilityProps>`
  ${mixins.text({ size: 'small' })}

  color: ${({ $isAvailable }) => ($isAvailable ? colors.success : colors.textHelper)};
  font-weight: 500;
  margin-top: 4px;
  white-space: nowrap;
`;

export const Subheading = styled.div`
  ${mixins.text({ isHeading: true })}

  margin-top: 16px;
`;

export const DescriptionLong = styled.div`
  ${mixins.text()}

  margin-top: 8px;
`;

export const Position = styled.div`
  margin-top: 16px;
`;

export const PositionHeading = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content auto;
`;

export const PositionHeadingText = styled.div`
  ${mixins.text({ size: 'small' })}
`;

export const PositionHeadingDivider = styled.div`
  background-color: ${colors.control};
  height: 2px;
`;

export const Attributes = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin: 16px 0;

  @media ${mediaQueries.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Attribute = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
`;

export const AttributeIcon = styled(Icon).attrs({ color: colors.textHelper })`
  flex-shrink: 0;
`;

export const AttributeText = styled.div``;

export const AttributeLabel = styled.div`
  ${mixins.text({ size: 'small' })}

  line-height: 100%;
  margin-bottom: 2px;
`;

export const AttributeValue = styled.div`
  ${mixins.text({ isHeading: true, size: 'small' })}

  line-height: 120%;
  margin-top: 2px;
`;

export const VideoIframe = styled.iframe`
  /* stylelint-disable property-no-unknown */
  aspect-ratio: 3 / 1;
  border-radius: 8px;
  margin: 16px 0;
  width: 100%;
`;

export const Actions = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-content: flex-end;
  grid-template-columns: auto max-content;
  margin-top: 12px;

  @media ${mediaQueries.mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;
