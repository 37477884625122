import React, { useCallback, useEffect, useRef } from 'react';
import { PayPal } from 'types/paypal';

import { useErrorLog } from 'hooks/useErrorLog/useErrorLog';

interface PayPalButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  onCreateOrder: PayPal.CreateOrder;
  onCompleteOrder: (orderId: string) => Promise<void>;
  onError?: () => void;
  onShippingChange?: PayPal.OnShippingChange;
}

export const PayPalButton: React.FC<PayPalButtonProps> = ({
  disabled,
  onClick,
  onCompleteOrder,
  onCreateOrder,
  onError,
  onShippingChange
}) => {
  const { logError } = useErrorLog();

  const payPalButtonRef = useRef<HTMLDivElement>(null);

  const orderId = useRef<string | null>(null);

  const onApprove = useCallback<PayPal.OnApprove>(async () => {
    if (orderId.current === null) {
      throw new Error('Unable to complete order because `orderId` is `undefined`.');
    }

    await onCompleteOrder(orderId.current);
  }, [onCompleteOrder]);

  const handleError = useCallback<PayPal.OnError>(
    (error) => {
      logError('PayPal checkout failed', { error, orderId: orderId.current });
      onError?.();
    },
    [logError, onError]
  );

  const onInit = useCallback<PayPal.OnInit>(
    (_, actions) => {
      Boolean(disabled) ? actions.disable() : actions.enable();
    },
    [disabled]
  );

  useEffect(() => {
    if (payPalButtonRef.current !== null) {
      payPalButtonRef.current.innerHTML = '';
    }

    window.paypal
      .Buttons({
        createOrder: async () => {
          orderId.current = await onCreateOrder();
          return orderId.current;
        },
        onApprove,
        onClick,
        onInit,
        onError: handleError,
        onShippingChange
      })
      .render('#paypal-button');
  }, [onApprove, onClick, onCompleteOrder, onCreateOrder, handleError, onInit, onShippingChange]);

  return <div id="paypal-button" ref={payPalButtonRef} />;
};
