import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';
import { easings } from 'styles/easings';
import { mediaQueries } from 'styles/mediaQueries';
import { Price } from 'components/Price/Price';

interface IsOpenProps {
  $isOpen: boolean;
}

export const Root = styled.div<IsOpenProps>`
  align-items: flex-end;
  display: grid;
  grid-template-columns: 600px;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: background-color 100ms linear;
  width: 100%;
  z-index: 1;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: auto;
    `}

  @media ${mediaQueries.mobile} {
    grid-template-columns: 100%;
  }
`;

export const Card = styled.div<IsOpenProps>`
  ${mixins.card()}

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${({ $isOpen }) => ($isOpen ? 'minmax(0, 1fr) max-content' : '100%')};
  height: ${({ $isOpen }) => ($isOpen ? 'auto' : 56)}px;
  margin-bottom: 16px;
  max-height: calc(100vh - 32px);
  padding: ${({ $isOpen }) => ($isOpen ? 16 : 8)}px;
  pointer-events: auto;
  transition: height 300ms ${easings.easeOutQuint};

  @media ${mediaQueries.mobile} {
    border-radius: 0;
    grid-template-rows: 1fr max-content;
    height: ${({ $isOpen }) => ($isOpen ? '100%' : '64px')};
    max-height: none;
    margin-bottom: 0;
  }
`;

interface BodyProps {
  $isShowingPaymentError: boolean;
}

export const Body = styled.div<BodyProps>`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 100%;
  grid-template-rows: ${({ $isShowingPaymentError }) =>
      $isShowingPaymentError ? 'max-content' : ''} max-content max-content 1fr max-content;
  overflow: hidden;
  padding-bottom: 16px;
`;

export const Header = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content 1fr max-content;
  height: 40px;
  overflow: hidden;

  @media ${mediaQueries.mobile} {
    height: 48px;
  }
`;

export const HeaderShoppingCartIcon = styled(Icon).attrs({
  color: colors.textHelper,
  name: 'shoppingCart'
})``;

export const HeaderText = styled.div`
  ${mixins.text({ isHeading: true })}
`;

export const Items = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: max-content 1fr 80px max-content minmax(72px, max-content);
  grid-template-rows: repeat(auto-fill, 48px);
  overflow: auto;
`;

export const Image = styled.img`
  border-radius: 8px;
  height: 48px;
  object-fit: cover;
  width: 72px;
`;

export const ProductName = styled.div`
  ${mixins.ellipsis()}
  ${mixins.text()}

  font-weight: 500;
`;

export const Quantity = styled.div`
  align-items: baseline;
  display: flex;
  gap: 4px;
`;

export const QuantitySymbol = styled.div`
  ${mixins.text({ isHeading: true })}

  font-weight: 500;
`;

export const ItemPrice = styled(Price)`
  ${mixins.text({ isHeading: true })}

  text-align: right;
`;

export const Divider = styled.div`
  border: 1px solid ${colors.control};
  height: 0;
  margin: 0 auto;
  width: 100%;
`;

export const TotalPrices = styled.div`
  align-items: baseline;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr max-content minmax(72px, max-content);
`;

export const TotalPriceLabel = styled.div`
  ${mixins.text({ size: 'small' })}

  font-weight: 500;
  grid-column-start: 2;
`;

export const TotalPriceValue = styled(Price)`
  ${mixins.text({ isHeading: true })}

  grid-column-start: 3;
  text-align: right;
`;

export const Footer = styled.div<IsOpenProps>`
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content 1fr max-content max-content;
  height: 100%;
  justify-content: flex-end;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      align-items: flex-end;
      grid-template-columns: max-content max-content;
      justify-content: space-between;
    `}

  @media ${mediaQueries.mobile} {
    ${({ $isOpen }) =>
      $isOpen &&
      css`
        grid-template-columns: repeat(2, calc(50% - 8px));
      `}
  }
`;

export const FooterShoppingCartIcon = styled(Icon).attrs({
  color: colors.textHelper,
  name: 'shoppingCart'
})`
  margin-left: 8px;
`;

export const ItemsSummary = styled.div`
  ${mixins.ellipsis()}
  ${mixins.text({ size: 'small' })}

  color: ${colors.textHelper};
  font-weight: 500;
`;

export const Subtotal = styled(Price)`
  ${mixins.text({ isHeading: true })}
`;
