import styled from 'styled-components';

import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { mixins } from 'styles/mixins';
import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const QuantitiesCard = styled.div`
  ${mixins.card()}

  max-width: 512px;
  width: 100%;
`;

export const Quantities = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: 1fr repeat(4, max-content);
`;

export const QuantitiesName = styled.div`
  ${mixins.ellipsis()}
  ${mixins.text({})}
`;

export const QuantitiesCurrent = styled.div`
  ${mixins.text({})}

  font-weight: 500;
`;

export const QuantitiesArrow = styled(Icon).attrs({
  color: colors.textHelper,
  name: 'arrowRight'
})``;

export const QuantitiesInput = styled(Input)`
  width: 80px;
`;

interface QuantitiesSaveButtonProps {
  $isVisible: boolean;
}

export const QuantitiesSaveButton = styled(Button)<QuantitiesSaveButtonProps>`
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
`;
