import styled, { keyframes } from 'styled-components';

import { Button } from 'components/Button/Button';
import { mixins } from 'styles/mixins';
import { mediaQueries } from 'styles/mediaQueries';

const rootAnimation = keyframes`
  from {
    background-color: rgba(0 0 0 / 0%);
  }

  to {
    background-color: rgba(0 0 0 / 85%);
  }
`;

export const Root = styled.div`
  align-items: center;
  animation: ${rootAnimation} 200ms linear forwards;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 16px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;
`;

export const CloseButton = styled(Button)`
  bottom: 16px;
  left: calc(50% - 20px);
  position: fixed;
  top: 16px;

  @media ${mediaQueries.mobile} {
    left: calc(50% - 24px);
  }
`;

const imageAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Image = styled.img`
  ${mixins.shadow({ size: 'large', strength: 'strong' })}

  animation: ${imageAnimation} 200ms ease-out forwards;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: calc(100vh - 144px);
  max-width: calc(100vw - 32px);
  position: fixed;
  width: auto;

  @media (orientation: portrait) {
    height: auto;
    width: 100%;
  }
`;

export const NavigationButtons = styled.div`
  bottom: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
`;
