import styled, { css } from 'styled-components';

import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

interface InputWrapperProps {
  $hasLabel: boolean;
  $isInvalid: boolean;
}

export const InputWrapper = styled.label<InputWrapperProps>`
  border: 2px solid ${colors.control};
  border-radius: 8px;
  display: block;
  height: ${({ $hasLabel }) => ($hasLabel ? 48 : 40)}px;
  overflow: hidden;
  position: relative;

  &:focus-within {
    border-color: ${colors.primary};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      border-color: ${colors.error} !important;
    `}
`;

interface LabelProps {
  $isEmpty: boolean;
  $isFocused: boolean;
  $isInvalid: boolean;
}

export const Label = styled.div<LabelProps>(({ $isEmpty, $isFocused, $isInvalid }) => {
  const displayAsPlaceholder = $isEmpty && !$isFocused;

  return css`
    ${mixins.text({ isHeading: true, size: displayAsPlaceholder ? 'default' : 'small' })}

    color: ${colors.textHelper};
    left: 8px;
    position: absolute;
    top: ${displayAsPlaceholder ? 14 : 4}px;
    transition: all 100ms ease-out;

    ${$isFocused &&
    css`
      color: ${colors.primary};
    `}

    ${$isInvalid &&
    css`
      color: ${colors.error};
    `}
  `;
});

interface InputElementProps {
  $hasLabel: boolean;
}

export const InputElement = styled.input<InputElementProps>`
  ${mixins.text()}

  border: none;
  color: ${colors.text};
  display: block;
  height: 100%;
  padding: ${({ $hasLabel }) => ($hasLabel ? '22px 8px 6px' : '12px 8px 12px')};
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const Hint = styled.div`
  ${mixins.text({ size: 'small' })}

  line-height: 120%;
  padding: 0 8px;
`;
