import React, { useRef } from 'react';

import { InputWrapper, Label, InputElement, Root, Hint } from './Input.styles';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  hint?: React.ReactNode;
  label?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = ({ hint, label, onBlur, onFocus, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);

  const isInvalid = isTouched && ref.current?.validity.valid === false;
  const hasLabel = Boolean(label);
  const isEmpty = props.value === '' || props.value === undefined;

  return (
    <Root>
      <InputWrapper $hasLabel={hasLabel} $isInvalid={isInvalid}>
        {label !== undefined && (
          <Label $isEmpty={isEmpty} $isFocused={isFocused} $isInvalid={isInvalid}>
            {label}
          </Label>
        )}
        <InputElement
          {...{ ...props, as: undefined }}
          $hasLabel={hasLabel}
          onFocus={(event) => {
            setIsFocused(true);
            setIsTouched(true);
            onFocus?.(event);
          }}
          onBlur={(event) => {
            setIsFocused(false);
            onBlur?.(event);
          }}
          ref={ref}
        />
      </InputWrapper>
      <Hint>{hint}</Hint>
    </Root>
  );
};
