import styled from 'styled-components';

import { mixins } from 'styles/mixins';

interface RootProps {
  $isExpanded: boolean;
}

export const Root = styled.div<RootProps>`
  ${mixins.card()}

  background: rgb(250 250 250);
  border-radius: 16px;
  cursor: ${({ $isExpanded }) => ($isExpanded ? 'auto' : 'pointer')};
  width: 100%;
`;
