import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { shoppingCartProductsQuery } from './api';
import {
  ShoppingCartProductsQuery,
  ShoppingCartProductsQuery_products
} from './__graphql__/ShoppingCartProductsQuery';

export interface ShoppingCartManagerProps {
  wholesaleCustomerId: string | null;
}

export interface ShoppingCartItem {
  product: ShoppingCartProductsQuery_products;
  quantity: number | null;
  size?: string;
}

export interface ShoppingCartManager {
  isWashingtonStateResident: boolean | null;
  items: ShoppingCartItem[];
  wholesaleCustomerId: string | null;
  addItem: (productId: string, size?: string) => void;
  refetchProducts: () => Promise<void>;
  removeItem: (productId: string, size?: string) => void;
  reset: () => void;
  setIsWashingtonStateResident: (value: boolean | null) => void;
  updateItemQuantity: (productId: string, quantity: number | null, size?: string) => void;
}

export const useShoppingCartManager = ({
  wholesaleCustomerId
}: ShoppingCartManagerProps): ShoppingCartManager => {
  const productResults = useQuery<ShoppingCartProductsQuery>(shoppingCartProductsQuery);
  const products = productResults.data?.products;

  const [isWashingtonStateResident, setIsWashingtonStateResident] = useState<boolean | null>(null);
  const [items, setItemsState] = useState<ShoppingCartItem[]>([]);

  const setItems = useCallback((items: ShoppingCartItem[]) => {
    setItemsState(items);
    localStorage.setItem('useShoppingCartManager.items', JSON.stringify(items));
  }, []);

  // Get items from localStorage and set state with current products
  useEffect(() => {
    if (products === undefined) return;

    const storedItemsRaw = localStorage.getItem('useShoppingCartManager.items');
    const storedItems =
      storedItemsRaw === null ? [] : (JSON.parse(storedItemsRaw) as ShoppingCartItem[]);

    const itemsWithProducts = [...storedItems].map<ShoppingCartItem | null>((item) => {
      const product = products.find(({ id }) => id === item.product.id);

      if (product === undefined) {
        return null;
      }

      return { ...item, product };
    });

    const newItems = itemsWithProducts.filter(Boolean) as ShoppingCartItem[];

    setItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const addItem = useCallback<ShoppingCartManager['addItem']>(
    (productId, size) => {
      const itemIndex = items.findIndex(
        (item) => item.product.id === productId && item.size === size
      );
      const newItems = [...items];

      if (itemIndex !== -1) {
        const currentQuantity = newItems[itemIndex].quantity;
        newItems[itemIndex].quantity = currentQuantity === null ? 1 : currentQuantity + 1;
        setItems(newItems);
        return;
      }

      if (products === undefined) return;
      const product = products.find((product) => product.id === productId);
      if (product === undefined) throw new Error('Unable to find product for shopping cart item.');

      newItems.push({ product, quantity: 1, size });
      setItems(newItems);
    },
    [products, items, setItems]
  );

  const removeItem = useCallback<ShoppingCartManager['removeItem']>(
    (productId, size) => {
      const itemIndex = items.findIndex(
        (item) => item.product.id === productId && item.size === size
      );
      if (itemIndex === -1) {
        throw new Error(
          `Can't remove item with productId "${productId}" because it is not in the shopping cart.`
        );
      }

      const newItems = [...items];
      newItems.splice(itemIndex, 1);
      setItems(newItems);
    },
    [items, setItems]
  );

  const reset = useCallback<ShoppingCartManager['reset']>(() => {
    setIsWashingtonStateResident(null);
    setItems([]);
  }, [setItems]);

  const updateItemQuantity = useCallback<ShoppingCartManager['updateItemQuantity']>(
    (productId, quantity, size) => {
      const itemIndex = items.findIndex(
        (item) => item.product.id === productId && item.size === size
      );
      if (itemIndex === -1) {
        throw new Error(
          `Can't update quantity for item with productId "${productId}" because it is not in the shopping cart.`
        );
      }

      const newItems = [...items];
      newItems[itemIndex].quantity = quantity;
      setItems(newItems);
    },
    [items, setItems]
  );

  return {
    isWashingtonStateResident,
    items,
    wholesaleCustomerId,
    addItem,
    refetchProducts: async () => {
      await productResults.refetch();
    },
    removeItem,
    reset,
    setIsWashingtonStateResident,
    updateItemQuantity
  };
};
