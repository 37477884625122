import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { RadioButtonWrapper, RadioButton, Root, Label } from './RadioButtonGroup.styles';

export interface RadioButtonGroupProps<T> {
  onChange: (newValue: T) => void;
  options: Array<{ label: string; value: T }>;
  value: T;
}

export const RadioButtonGroup = <T extends unknown>({
  onChange,
  options,
  value: currentValue
}: RadioButtonGroupProps<T>): ReturnType<React.FC<RadioButtonGroupProps<T>>> => {
  const [id] = useState(uuid());

  return (
    <Root>
      {options.map(({ label, value }) => (
        <RadioButtonWrapper key={String(value)}>
          <RadioButton
            name={id}
            onChange={(event) => {
              if (!event.target.checked) return;
              onChange(value);
            }}
            type="radio"
            defaultChecked={value === currentValue}
          />
          <Label>{label}</Label>
        </RadioButtonWrapper>
      ))}
    </Root>
  );
};
