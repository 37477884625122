import styled from 'styled-components';

import { Price } from 'components/Price/Price';
import { mixins } from 'styles/mixins';
import { colors } from 'styles/colors';
import { Title } from 'components/Title/Title';

export const StyledTitle = styled(Title)`
  margin-top: 16px;
`;

export const DetailsAndPrice = styled.div`
  align-items: flex-end;
  display: grid;
  gap: 32px;
  grid-template-columns: auto auto;
  margin-top: 8px;
`;

export const DescriptionShortAndHideDetails = styled.div`
  align-items: flex-end;
  display: grid;
  gap: 32px;
  grid-template-columns: auto 200px;
  margin-top: 8px;
`;

export const LocationAndNextClass = styled.div`
  ${mixins.text()}
`;

export const DescriptionShort = styled.div`
  ${mixins.text()}

  margin-top: 8px;
`;

export const PriceAndActions = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledPrice = styled(Price)`
  ${mixins.text({
    isHeading: true,
    size: 'extraLarge'
  })}
`;

export const Subheading = styled.div`
  ${mixins.text({ isHeading: true })}

  margin-bottom: 8px;
  margin-top: 16px;
`;

export const DescriptionLong = styled.div`
  ${mixins.text()}

  white-space: pre-wrap;
`;

export const EventList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Event = styled.div`
  align-items: center;
  background: ${colors.lightBackground};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  padding: 8px;
`;

export const EventSessions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EventSession = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const EventSessionDate = styled.div`
  ${mixins.text({ isHeading: true })}

  color: ${colors.textHelper};
`;

export const EventSessionTime = styled.div`
  ${mixins.text({ size: 'small' })}

  color: ${colors.textHelper};
`;

export const AvailabilityAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: auto;
  white-space: nowrap;
`;

export const EventPrice = styled(Price)`
  ${mixins.text({
    isHeading: true,
    size: 'large'
  })}

  text-align: right;
`;
