import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/colors';
import { mediaQueries } from 'styles/mediaQueries';
import { mixins } from 'styles/mixins';

const rootAnimation = keyframes`
  from {
    background-color: rgba(0 0 0 / 0%);
  }

  to {
    background-color: rgba(0 0 0 / 40%);
  }
`;

export const Root = styled.div`
  animation: ${rootAnimation} 200ms linear forwards;
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`;

const cardAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Card = styled.div`
  ${mixins.card()}

  animation: ${cardAnimation} 200ms ease-out forwards;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 32px);
  width: 400px;

  @media ${mediaQueries.mobile} {
    border-radius: 0;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  ${mixins.text({ isHeading: true, size: 'large' })}

  margin-bottom: 4px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DetailsText = styled.div`
  ${mixins.text()}

  color: ${colors.textHelper};
  line-height: 1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`;

export const ClassPolicyLink = styled.a`
  text-decoration: underline;
`;
