import gql from 'graphql-tag';

export const storeProductsQuery = gql`
  query StoreProductsQuery {
    products(orderBy: [{ position: asc }]) {
      id
      positions(orderBy: [{ position: asc }]) {
        id
        quantity
        part {
          name
          attributes(orderBy: [{ position: asc }]) {
            icon
            label
            value
          }
        }
      }
      availableAmount
      descriptionLong
      descriptionShort
      id
      imageUrls
      name
      price
      readableId
      sizes
      wholesaleDiscount
      youtubeId
    }
  }
`;
