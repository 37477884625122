import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Button } from 'components/Button/Button';

import { CloseButton, Image, NavigationButtons, Root } from './Lightbox.styles';

export interface LightboxProps {
  imageUrls: readonly string[];
  index?: number;
  setIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const Lightbox: React.FC<LightboxProps> = ({ imageUrls, index, setIndex }) => {
  const previous = useCallback(() => {
    setIndex((currentIndex) => {
      if (currentIndex === undefined) return undefined;
      return currentIndex === 0 ? imageUrls.length - 1 : currentIndex - 1;
    });
  }, [setIndex, imageUrls.length]);

  const next = useCallback(() => {
    setIndex((currentIndex) => {
      if (currentIndex === undefined) return undefined;
      return currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1;
    });
  }, [setIndex, imageUrls.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          previous();
          break;
        case 'ArrowRight':
          event.preventDefault();
          next();
          break;
        case 'Escape':
          setIndex(undefined);
          break;
      }
    },
    [next, previous, setIndex]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const content =
    index === undefined ? null : (
      <Root
        onClick={(event) => {
          if (event.target !== event.currentTarget) return;
          setIndex(undefined);
        }}
      >
        <CloseButton icon="close" onClick={() => setIndex(undefined)} />
        <Image src={imageUrls[index]} />
        <NavigationButtons>
          <Button icon="chevronLeft" onClick={previous} />
          <Button icon="chevronRight" onClick={next} />
        </NavigationButtons>
      </Root>
    );

  return ReactDOM.createPortal(content, document.getElementById('overlays')!);
};
