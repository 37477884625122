import styled from 'styled-components';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

export const Root = styled.div`
  position: relative;
`;

export const ScrollButtonBase = styled.button`
  ${mixins.shadow({ strength: 'strong' })}

  align-items: center;
  background-color: ${colors.control};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 16px);
  width: 32px;
`;

export const ScrollButtonLeft = styled(ScrollButtonBase)`
  left: -8px;
`;

export const ScrollButtonRight = styled(ScrollButtonBase)`
  right: -8px;
`;

export const ScrollButtonIcon = styled(Icon).attrs({ color: colors.controlText })``;

export const ScrollContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 120px;
  overflow-x: auto;
`;

export const Image = styled.img`
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
`;
