import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Title } from 'components/Title/Title';
import { Text } from 'components/NewsletterSubscribe/NewsletterSubscribe.styles';

import {
  ConfirmNewsletterSubscriberMutation,
  ConfirmNewsletterSubscriberMutationVariables
} from './__graphql__/ConfirmNewsletterSubscriberMutation';
import { confirmNewsletterSubscriberMutation } from './api';

export const NewsletterConfirm: React.FC = () => {
  const params = useParams<{ email: string; token: string }>();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const [confirmNewsletterSubscriber] = useMutation<
    ConfirmNewsletterSubscriberMutation,
    ConfirmNewsletterSubscriberMutationVariables
  >(confirmNewsletterSubscriberMutation, {
    onCompleted: () => setIsConfirmed(true),
    variables: params
  });

  useEffect(() => {
    void confirmNewsletterSubscriber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isConfirmed ? (
        <div>
          <Title>Newsletter subscription confirmed</Title>
          <Text>Thank you for subscribing to our newsletter.</Text>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};
