import React from 'react';

import { AvailabilityCount, AvailabilityText } from './LocalClassAvailability.styles';

export interface LocalClassAvailabilityProps {
  event: { maxStudentCount: number; reservedSpotsCount: number };
  hideCount?: boolean;
}

export const LocalClassAvailability: React.FC<LocalClassAvailabilityProps> = ({
  event,
  hideCount
}) => {
  const areNoSpotsLeft = event.reservedSpotsCount === event.maxStudentCount;

  if (hideCount === true) {
    return (
      <AvailabilityText>
        {areNoSpotsLeft ? 'No more spots available' : 'Spots still available'}
      </AvailabilityText>
    );
  }

  return (
    <div>
      <AvailabilityCount $areNoSpotsLeft={areNoSpotsLeft}>
        {event.maxStudentCount - event.reservedSpotsCount}/{event.maxStudentCount}
      </AvailabilityCount>{' '}
      <AvailabilityText>spots available</AvailabilityText>
    </div>
  );
};
