import React, { useCallback } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { useErrorLog } from 'hooks/useErrorLog/useErrorLog';

export const ErrorBoundary: React.FC = ({ children }) => {
  const { logError } = useErrorLog();

  const handleError = useCallback(
    (error: Error, info: { componentStack: string }): void => {
      logError(error.message, info);
    },
    [logError]
  );

  const errorMessage = (
    <>
      Something went wrong. Please reload the page and try again.
      <br />
      If the error persists, please{' '}
      <a href="mailto:service@mathewsonmetals.com" target="_blank">
        contact us
      </a>
      .
    </>
  );

  return (
    <ReactErrorBoundary fallbackRender={() => errorMessage} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};
