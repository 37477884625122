import gql from 'graphql-tag';

export const localClassesQuery = gql`
  query LocalClassesQuery($includeEventsAfter: DateTime!) {
    localClasses(orderBy: [{ position: asc }]) {
      descriptionShort
      descriptionLong
      id
      imageUrls
      location
      name
      readableId
      events(
        where: { sessions: { every: { from: { gt: $includeEventsAfter } } } }
        orderBy: [{ createdAt: asc }]
      ) {
        id
        maxStudentCount
        price
        reservedSpotsCount
        sessions(orderBy: [{ from: asc }]) {
          id
          from
          to
        }
      }
    }
  }
`;

export const completeOrderMutation = gql`
  mutation CompleteLocalClassOrderMutation($input: CompleteLocalClassOrderInput!) {
    completeLocalClassOrder(input: $input)
  }
`;

export const createOrderMutation = gql`
  mutation CreateLocalClassOrderMutation($input: CreateLocalClassOrderInput!) {
    createLocalClassOrder(input: $input) {
      payPalOrderId
    }
  }
`;

export const trackAnalyticsEventMutation = gql`
  mutation TrackAnalyticsEventMutation($event: ANALYTICS_EVENT!) {
    trackAnalyticsEvent(event: $event)
  }
`;
