import React from 'react';

import { Title } from 'components/Title/Title';
import { Paragraph } from 'components/Paragraph/Paragraph';

export const ReturnPolicy: React.FC = () => {
  return (
    <div>
      <Title>Return Policy</Title>
      <Paragraph>
        <p>
          Our policy lasts 14 days. If 14 days have gone by since your purchase, unfortunately we
          can’t offer you a refund or exchange.
        </p>
        <p>
          To be eligible for a return, your item must be unused and in the same condition that you
          received it. It must also be in the original packaging.
        </p>
        <p>
          Several types of goods are exempt from being returned. Perishable goods such as food,
          flowers, newspapers or magazines cannot be returned. We also do not accept products that
          are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.
        </p>
        <p>
          Additional non-returnable items: * Gift cards * Downloadable software products * Some
          health and personal care items
        </p>
        <p>To complete your return, we require a receipt or proof of purchase.</p>
        <p>Please do not send your purchase back to the manufacturer.</p>
        <p>
          There are certain situations where only partial refunds are granted: (if applicable) *
          Book with obvious signs of use * CD, DVD, VHS tape, software, video game, cassette tape,
          or vinyl record that has been opened. * Any item not in its original condition, is damaged
          or missing parts for reasons not due to our error. * Any item that is returned more than
          14 days after delivery
        </p>
        <p>
          Refunds (if applicable) Once your return is received and inspected, we will send you an
          email to notify you that we have received your returned item. We will also notify you of
          the approval or rejection of your refund. If you are approved, then your refund will be
          processed, and your PayPal transaction will be refunded.
        </p>
        <p>
          Late or missing refunds (if applicable) If you haven’t received a refund yet, first check
          your PayPal account again. There is often some processing time before a refund is posted.
          If you’ve done this and you still have not received your refund yet, please contact us at{' '}
          <a href="mailto:service@mathewsonmetals.com" target="_blank">
            service@mathewsonmetals.com
          </a>
          .
        </p>
        <p>
          Sale items (if applicable) Only regular priced items may be refunded, unfortunately sale
          items cannot be refunded.
        </p>
        <p>
          Exchanges (if applicable) We only replace items if they are defective or damaged. If you
          need to exchange it for the same item, send us an email at{' '}
          <a href="mailto:service@mathewsonmetals.com" target="_blank">
            service@mathewsonmetals.com
          </a>{' '}
          and send your item to: 32514 36th Ave E Eatonville, WA 98328, United States.
        </p>
        <p>
          You will be responsible for paying for your own shipping costs for returning your item.
          Shipping costs are non-refundable. If you receive a refund, the cost of return shipping
          will be deducted from your refund.
        </p>
        <p>
          Depending on where you live, the time it may take for your exchanged product to reach you,
          may vary.
        </p>
        <p>
          If you are shipping an item over $75, you should consider using a trackable shipping
          service or purchasing shipping insurance. We don’t guarantee that we will receive your
          returned item.
        </p>
      </Paragraph>
    </div>
  );
};
