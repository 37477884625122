import styled from 'styled-components';

import { Button } from 'components/Button/Button';
import { mediaQueries } from 'styles/mediaQueries';
import { mixins } from 'styles/mixins';

export const Root = styled.div`
  ${mixins.card()}
  ${mixins.shadow({ size: 'large', strength: 'default' })}

  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  ${mixins.text({ isHeading: true, size: 'extraLarge' })}

  margin-bottom: 8px;
  width: 100%;
`;

export const Text = styled.div`
  ${mixins.text()}

  width: 100%;
`;

export const SubscribeButton = styled(Button)`
  @media ${mediaQueries.mobile} {
    width: 100%;
  }
`;
