import React from 'react';

import { Root } from './Navigation.styles';
import { NavigationItem } from './NavigationItem';

export const Navigation: React.FC = () => {
  const storedPassword = localStorage.getItem('adminPassword');

  return (
    <Root>
      <NavigationItem icon="store" path="/store" text="Store" />
      <NavigationItem icon="school" path="/classes" text="Classes" />
      {storedPassword !== null && (
        <NavigationItem icon="settings" path="/admin" text="Administration" />
      )}
    </Root>
  );
};
