import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Input } from 'components/Input/Input';
import { Form, Root, LogInButton } from 'components/AdministrationLogin/AdministrationLogin.styles';
import { Message } from 'components/Message/Message';

import {
  CheckPasswordMutation,
  CheckPasswordMutationVariables
} from './__graphql__/CheckPasswordMutation';
import { checkPasswordMutation } from './api';

export const AdministrationLogin: React.FC = () => {
  const history = useHistory();

  const presetPassword = process.env.REACT_APP_PRESET_ADMIN_PASSWORD;
  const [password, setPassword] = useState(presetPassword === undefined ? '' : presetPassword);

  const [isShowingError, setIsShowingError] = useState(false);

  const [checkPassword, { loading: isCheckingPassword }] = useMutation<
    CheckPasswordMutation,
    CheckPasswordMutationVariables
  >(checkPasswordMutation);

  const submit = useCallback(async () => {
    setIsShowingError(false);

    try {
      await checkPassword({ variables: { adminPassword: password } });

      localStorage.setItem('adminPassword', password);
      history.push('/admin');
    } catch {
      setIsShowingError(true);
    }
  }, [checkPassword, password, history]);

  return (
    <Root>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          void submit();
        }}
      >
        {isShowingError && (
          <Message title="Unable to log in. Did you enter the correct password?" type="error" />
        )}
        <Input
          label="Password"
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          value={password}
        />
        <LogInButton disabled={isCheckingPassword} icon="arrowRight" text="Log in" type="primary" />
      </Form>
    </Root>
  );
};
