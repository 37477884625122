import React from 'react';

import { IconName } from 'components/Icon/Icon';

import { DropdownTrigger, Root, StyledIcon, Text } from './Button.styles';

export type ButtonProps = {
  disabled?: boolean;
  className?: string;
  icon: IconName;
  text?: string;
  type?: 'default' | 'primary';
} & (
  | {
      dropdownOptions?: never;
      onClick?: () => void;
    }
  | {
      dropdownOptions: readonly string[];
      onClick?: (dropdownOption: string) => void;
    }
);

export const Button: React.FC<ButtonProps> = (props) => {
  const { disabled, className, icon, text, type } = props;

  return (
    <Root
      $hasText={Boolean(text)}
      $type={type}
      className={className}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();

        if (props.dropdownOptions) {
          return;
        } else {
          props.onClick?.();
        }
      }}
    >
      {props.dropdownOptions && (
        <DropdownTrigger
          defaultValue="choose"
          onChange={(event) => {
            const value = event.target.value;

            if (!value) return;

            props.onClick?.(value);
            event.target.value = '';
          }}
        >
          <option disabled value="choose">
            Choose
          </option>
          {props.dropdownOptions.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </DropdownTrigger>
      )}
      <StyledIcon $type={type} name={icon} />
      {text !== undefined && <Text $type={type}>{text}</Text>}
    </Root>
  );
};
