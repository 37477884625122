import React, { useCallback, useContext } from 'react';

import { Button } from 'components/Button/Button';
import { ExpandableCard } from 'components/ExpandableCard/ExpandableCard';
import { IconName } from 'components/Icon/Icon';
import { ShoppingCartContext } from 'contexts/ShoppingCartContext';
import { useIsMobile } from 'hooks/useIsMobile';

import { ImageCarousel } from './ImageCarousel';
import {
  Actions,
  Attribute,
  AttributeIcon,
  AttributeLabel,
  Attributes,
  AttributeText,
  AttributeValue,
  Availability,
  Body,
  DescriptionLong,
  DescriptionShort,
  Name,
  Position,
  PositionHeading,
  PositionHeadingDivider,
  PositionHeadingText,
  PriceAndAvailability,
  StyledPrice,
  Subheading,
  VideoIframe
} from './ProductCard.styles';
import { StoreProductsQuery_products } from './__graphql__/StoreProductsQuery';

interface Props {
  product: StoreProductsQuery_products;
}

export const ProductCard: React.FC<Props> = ({ product }) => {
  const isMobile = useIsMobile();

  const shoppingCart = useContext(ShoppingCartContext);

  const handleAddToCart = useCallback(
    (size?: string) => {
      shoppingCart.addItem(product.id, size);
    },
    [shoppingCart, product]
  );

  const isAvailable = product.availableAmount !== 0;

  const priceAndAvailability = (
    <PriceAndAvailability>
      <StyledPrice
        discount={shoppingCart.wholesaleCustomerId !== null ? product.wholesaleDiscount : 0}
        hideZeroFractionDigits
        value={product.price}
      />
      <Availability $isAvailable={isAvailable}>
        {isAvailable ? `${product.availableAmount} available` : 'Not available'}
      </Availability>
    </PriceAndAvailability>
  );

  return (
    <ExpandableCard basePath="/store" path={`/store/${product.readableId}`}>
      {({ collapse, expand, isExpanded }) => {
        const actions = (
          <Actions>
            <Button
              icon={isExpanded ? 'chevronUp' : 'chevronDown'}
              onClick={() => {
                isExpanded ? collapse() : expand();
              }}
              text={isExpanded ? 'Hide details' : 'Show details'}
            />
            {product.sizes.length ? (
              <Button
                disabled={product.availableAmount === 0}
                dropdownOptions={product.sizes}
                icon="shoppingCartAdd"
                onClick={(size) => handleAddToCart(size)}
                text="Add to cart"
                type="primary"
              />
            ) : (
              <Button
                disabled={product.availableAmount === 0}
                icon="shoppingCartAdd"
                onClick={handleAddToCart}
                text="Add to cart"
                type="primary"
              />
            )}
          </Actions>
        );

        return (
          <>
            <ImageCarousel imageUrls={product.imageUrls} lightboxDisabled={!isExpanded} />
            <Body>
              <div>
                <Name>{product.name}</Name>
                <DescriptionShort>{product.descriptionShort}</DescriptionShort>
              </div>
              <div>
                {priceAndAvailability}
                {!isMobile && actions}
              </div>
            </Body>
            {isMobile && actions}
            {isExpanded && (
              <>
                <Subheading>Description</Subheading>
                <DescriptionLong>{product.descriptionLong}</DescriptionLong>
                {product.positions.length !== 0 && <Subheading>Included</Subheading>}
                {product.positions.map((position) => (
                  <Position key={position.id}>
                    <PositionHeading>
                      <PositionHeadingText>
                        {position.quantity}x {position.part.name}
                      </PositionHeadingText>
                      {position.part.attributes.length !== 0 && <PositionHeadingDivider />}
                    </PositionHeading>
                    {position.part.attributes.length !== 0 && (
                      <Attributes>
                        {position.part.attributes.map((attribute, index) => (
                          <Attribute key={index}>
                            <AttributeIcon name={attribute.icon as IconName} />
                            <AttributeText>
                              {attribute.label !== null && (
                                <AttributeLabel>{attribute.label}</AttributeLabel>
                              )}
                              <AttributeValue>{attribute.value}</AttributeValue>
                            </AttributeText>
                          </Attribute>
                        ))}
                      </Attributes>
                    )}
                  </Position>
                ))}
                {product.youtubeId !== null && (
                  <VideoIframe
                    src={`https://www.youtube.com/embed/${product.youtubeId}`}
                    frameBorder="0"
                    allowFullScreen
                  />
                )}
                {priceAndAvailability}
                {actions}
              </>
            )}
          </>
        );
      }}
    </ExpandableCard>
  );
};
