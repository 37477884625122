import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ClassTestimonial = styled.div`
  ${mixins.card()}

  margin-bottom: 16px;
`;

export const ClassTestimonialText = styled.div`
  ${mixins.text()}

  font-family: 'Roboto Slab';

  &::after {
    content: '”';
  }

  &::before {
    content: '“';
  }
`;

export const ClassTestimonialAuthorName = styled.div`
  ${mixins.text()}

  color: ${colors.text};
  margin-top: 8px;

  &::before {
    content: '— ';
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 16px;
  margin-top: 24px;
`;

const footerLinkStyles = css`
  ${mixins.text({ size: 'small' })}

  color: rgb(140, 140, 140);

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterExternalLink = styled.a`
  ${footerLinkStyles}
`;

export const FooterLink = styled(Link)`
  ${footerLinkStyles}
`;
