export const formatDate = (date: Date | string): string => {
  const format = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    timeZone: 'America/Los_Angeles',
    year: 'numeric'
  });
  return format.format(new Date(date));
};

export const formatTimeRange = (fromDate: Date | string, toDate: Date | string): string => {
  const format = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Los_Angeles'
  });
  return `${format.format(new Date(fromDate))} – ${format.format(new Date(toDate))}`;
};

export const getFormattedClassEventDates = (event: {
  sessions: ReadonlyArray<{ from: string; to: string }>;
}): string => {
  const startDate = formatDate(event.sessions[0].from);
  const endDate = formatDate(event.sessions[event.sessions.length - 1].to);
  return startDate !== endDate ? `${startDate} – ${endDate}` : startDate;
};

export const roundPrice = (value: number): number => Number(value.toFixed(2));
