import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Icon } from 'components/Icon/Icon';
import { colors } from 'styles/colors';
import { mixins } from 'styles/mixins';

interface BaseProps {
  $isActive: boolean;
  $isMobile: boolean;
}

export const Root = styled(Link)<BaseProps>`
  align-items: center;
  background-color: ${({ $isActive }) => ($isActive ? colors.primary : 'transparent')};
  border-radius: ${({ $isMobile }) => ($isMobile ? 24 : 16)}px;
  display: flex;
  gap: 8px;
  height: ${({ $isMobile }) => ($isMobile ? 48 : 32)}px;
  padding: ${({ $isMobile }) => ($isMobile ? '0 16px 0 12px' : '0 12px 0 8px')};
`;

export const StyledIcon = styled(Icon).attrs<BaseProps>(({ $isActive }) => ({
  color: $isActive ? colors.white : colors.primary
}))<BaseProps>`
  height: ${({ $isMobile }) => ($isMobile ? 32 : 24)}px;
  width: ${({ $isMobile }) => ($isMobile ? 32 : 24)}px;
`;

export const Text = styled.div<BaseProps>`
  ${({ $isMobile }) => mixins.text({ isHeading: true, size: $isMobile ? 'large' : 'default' })}

  color: ${({ $isActive }) => ($isActive ? colors.white : colors.primary)};
`;
