import React from 'react';

import { TextSize } from 'styles/mixins';

import { Root, Title, Body } from './Message.styles';

export interface MessageProps {
  className?: string;
  textSize?: TextSize;
  title?: string;
  type?: 'default' | 'error' | 'success';
}

export const Message: React.FC<MessageProps> = ({ className, children, textSize, title, type }) => {
  return (
    <Root className={className} $type={type}>
      {title !== undefined && <Title $type={type}>{title}</Title>}
      <Body $textSize={textSize} $type={type}>
        {children}
      </Body>
    </Root>
  );
};
